.featureChildren-content {
  display: flex;
  flex-flow: row nowrap;
}

.featureChildren-table {
  flex: 1 1 auto;
}
.featureChildren-table td {
  vertical-align: middle !important;
}

.featureChildren-loader {
  background: white;
  border-radius: 0 0 5px 5px;
  padding: 30px;
}

.featureChildren-pagination {
  text-align: center;
}

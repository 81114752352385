.campaigns-titleBar {
  margin-bottom: 15px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.campaigns-titleBar .campaigns-actions {
  margin-left: auto;
}

.campaign {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.campaign h3 {
  margin: 0;
}

.campaign__actions {
  margin-left: auto;
}

.campaigns-list .table > tbody > tr > td {
  vertical-align: middle;
}
.campaigns-list .campaigns--actions {
  text-align: right;
}

.campaigns .panel-body h3 {
  padding: 50px 30px;
  text-align: center;
}

.campaigns .empty-message h3 {
  padding: 0;
}

/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.waypoints-actions {
  text-align: right;
}
.waypoints-actions .btn-primary,
.waypoints-actions .btn-default {
  margin-left: 10px;
}

.sortable-waypoint {
  background-color: rgba(68, 184, 223, 0.1);
  list-style-type: none;
  min-height: 62px;
  padding: 13px 15px;
  visibility: visible;
  z-index: 99999999;
}
.sortable-waypoint div.content {
  visibility: hidden;
}
.sortable-waypoint div.handle {
  visibility: hidden;
}

.outingRoute {
  display: flex;
  flex: 1 1 100%;
  flex-flow: row nowrap;
  height: 100%;
  position: absolute;
  width: 100%;
}
.outingRoute ol.waypointList {
  margin-bottom: 0;
  padding-left: 0;
}
.outingRoute ol.waypointList li.waypointListItem {
  border-bottom: solid 1px #d8d8d8;
  cursor: pointer;
  display: flex;
  list-style-type: none;
  min-height: 62px;
  padding: 13px 15px;
}
.outingRoute ol.waypointList li.waypointListItem:hover {
  background-color: rgba(68, 184, 223, 0.1);
}
.outingRoute ol.waypointList li.waypointListItem div.marker {
  height: 27px;
  line-height: 27px;
  width: 38px;
}
.outingRoute ol.waypointList li.waypointListItem div.marker div {
  font-weight: bold;
  height: 100%;
  line-height: 27px;
  text-align: center;
  width: 100%;
}
.outingRoute ol.waypointList li.waypointListItem div.content {
  flex: 1;
  min-height: 27px;
}
.outingRoute ol.waypointList li.waypointListItem div.content .name {
  font-weight: bold;
  line-height: 16px;
}
.outingRoute ol.waypointList li.waypointListItem div.content .details {
  color: #aab2bd;
  font-size: 12px;
}
.outingRoute ol.waypointList li.waypointListItem div.handle {
  height: 27px;
  line-height: 27px;
  width: 38px;
}
.outingRoute ol.waypointList li.waypointListItem div.handle .btn {
  font-size: 16px;
  color: #6d6e70;
  cursor: move;
}
.outingRoute ol.waypointList li.waypointListItem div.handle .btn:hover, .outingRoute ol.waypointList li.waypointListItem div.handle .btn:active, .outingRoute ol.waypointList li.waypointListItem div.handle .btn:focus {
  color: #404041;
}
.outingRoute .btn-coral {
  -webkit-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
  background-color: #ef6643;
  border-color: transparent;
  font-family: "Karla", sans-serif;
}
.outingRoute .btn-coral:focus, .outingRoute .btn-coral:hover {
  background-color: #e07a60;
  color: #fff;
}
.outingRoute .mapboxgl-ctrl-add-vertices.pressed,
.outingRoute .mapboxgl-ctrl-add-vertices.pressed:hover,
.outingRoute .mapboxgl-ctrl-edit-line.pressed,
.outingRoute .mapboxgl-ctrl-edit-line.pressed:hover {
  background-color: #98ca43;
}

.outingRoute-sidebar {
  background: white;
  border-right: 1px solid #E6E9ED;
  display: flex;
  flex-flow: column nowrap;
  overflow-y: auto;
  width: 500px;
}
.outingRoute-sidebar .outingRoute-sidebar-uploadPanel {
  margin-bottom: 0;
}
.outingRoute-sidebar .outingRoute-waypointPanel-heading button {
  float: right;
  padding: 2px 8px;
}
.outingRoute-sidebar .outingRoute-waypointPanel.panel {
  display: flex;
  flex: 2 1 100%;
  flex-direction: column;
  margin-bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
.outingRoute-sidebar .outingRoute-waypointPanel.panel .panel-body {
  flex-grow: 1;
  min-height: 0;
  padding: 0;
}
.outingRoute-sidebar .outingRoute-waypointPanel.panel .panel-body .help {
  background-color: #e0f2c2;
  padding: 13px 15px;
}
.outingRoute-sidebar .outingRoute-waypointPanel.panel .panel-body .help .title {
  color: #5e793a;
  font-weight: bold;
}
.outingRoute-sidebar .outingRoute-waypointPanel.panel .panel-body .help .content {
  color: #5e793a;
  font-size: 12px;
}

.outingRoute-content {
  flex: 1 1 auto;
  overflow-y: hidden;
  position: relative;
}
.outingRoute-content .mapPreview {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.featureStatusDropdown .btn {
  min-width: 85px;
  text-align: left;
}
.featureStatusDropdown .featureStatus-icon {
  display: inline-block;
  border-radius: 100px;
  height: 10px;
  width: 10px;
  background: #404041;
  margin-right: 5px;
}
.featureStatusDropdown .featureStatus-icon.featureStatus-icon--open {
  background: #7da836;
}
.featureStatusDropdown .featureStatus-icon.featureStatus-icon--closed {
  background: #c55337;
}

.outingsTable-tableRow > td:first-child {
  width: 100%;
}
.outingsTable-tableRow .form-group,
.outingsTable-tableRow .checkbox {
  margin: 0;
}

.outingsTable-progress p {
  margin: 0 0 5px;
}
.outingsTable-progress .progress {
  margin: 0;
}

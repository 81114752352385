.locations-map {
  display: flex;
  flex: 2 1 100%;
  flex-flow: column nowrap;
  overflow: hidden;
}
.locations-map .dashboard-header__titleText {
  margin-bottom: 15px;
}
.locations-map .dashboard-header__titleHelp {
  margin-left: auto;
  margin-top: -2px;
}
.locations-map .pageLayout,
.locations-map .pageLayout-content {
  -webkit-flex: 2 1 100%;
  -webkit-flex-flow: column nowrap;
  display: -webkit-flex;
  display: flex;
  flex-flow: column nowrap;
  flex: 2 1 100%;
  padding: 0;
}
.locations-map .pageLayout-content {
  position: relative;
}
.locations-map .locations-map-wrapper {
  -webkit-flex: 1 1 100%;
  -webkit-flex-flow: row nowrap;
  display: -webkit-flex;
  display: flex;
  flex: 1 1 100%;
  flex-flow: row nowrap;
  height: 100%;
  position: absolute;
  width: 100%;
}

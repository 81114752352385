/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.mediaModal-dialog {
  width: 100%;
  max-width: 900px;
}

.mediaModal-titleBar {
  background: #E6E9ED;
  border-radius: 5px 5px 0 0;
}

.mediaModal-titleBar__content {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.mediaModal-actions {
  margin-left: auto;
}
.mediaModal-actions > * {
  margin-left: 8px;
}

.mediaModal-dialog .mediaList-wrap {
  padding: 15px 0;
}
.mediaModal-dialog .mediaList-wrap .mediaActionBar-wrap {
  margin-bottom: 10px;
}

.featuresList-wrap {
  padding: 15px;
}

.featuresList-content {
  display: flex;
  flex-flow: row nowrap;
}

.featuresList-table {
  flex: 1 1 auto;
}
.featuresList-table td {
  vertical-align: middle !important;
}

.featuresList-loader {
  background: white;
  border-radius: 0 0 5px 5px;
  padding: 30px;
}

.featuresList-pagination {
  text-align: center;
}

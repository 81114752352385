/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.uploader {
  display: none;
  height: 200px;
  position: relative;
}
.uploader .uploader-close.btn {
  color: #6d6e70;
  font-size: 16px;
  position: absolute;
  right: 10px;
  top: 10px;
}
.uploader .uploader-container {
  border: 5px dashed #aab2bd;
  border-radius: 0;
  bottom: 0;
  left: 0;
  margin-bottom: 15px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 500;
}
.uploader .uploader-container.over {
  background-color: rgb(254, 251, 228);
}
.uploader .uploader-container .uploader-container-inner {
  text-align: center;
}

/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.visibilityDropdown .btn {
  min-width: 85px;
  text-align: left;
}
.visibilityDropdown .btn:hover {
  color: #434A54;
}
.visibilityDropdown .visibility-icon {
  background: #404041;
  border-radius: 100px;
  display: inline-block;
  height: 10px;
  margin-right: 5px;
  width: 10px;
}
.visibilityDropdown .visibility-icon.visibility-icon--archive, .visibilityDropdown .visibility-icon.visibility-icon--archived {
  background: #c55337;
}
.visibilityDropdown .visibility-icon.visibility-icon--draft {
  background: #FFCE54;
}
.visibilityDropdown .visibility-icon.visibility-icon--publish, .visibilityDropdown .visibility-icon.visibility-icon--published {
  background: #44a6cf;
}

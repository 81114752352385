.topbar__nav {
  margin: 0;
}

.topbar__logo {
  float: left;
  display: inline-block;
  width: 18px;
  height: 30px;
  margin: 10px 10px 0 0;
}

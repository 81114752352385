/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.mediaList-content {
  max-width: 100%;
}

.mediaList-list {
  display: flex;
  flex-flow: row wrap;
  margin-left: -5px;
  margin-right: -5px;
}

.mediaList-listItem {
  display: block;
  flex: 0 1 135px;
  padding: 5px;
  max-width: 155px;
}

.mediaList-pagination {
  text-align: center;
}

.mediaList-table-panel {
  width: 100%;
}

.mediaList-table {
  width: 100%;
}
.mediaList-table table {
  table-layout: fixed;
}
.mediaList-table th {
  white-space: nowrap;
}
.mediaList-table td.nowrap {
  white-space: nowrap;
}
.mediaList-table td {
  word-break: break-word;
}

.mediaList-table .mediaList-listItem {
  width: 120px;
}

.mediaTable-none {
  font-style: italic;
  color: red;
}

/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.analytics-titleBar {
  margin-bottom: 15px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.analytics-titleBar .analytics-actions {
  margin-left: auto;
}

.analytics-form .panel-body h3 {
  padding: 50px 30px;
  text-align: center;
}
.analytics-form .month-preset-buttons {
  margin-bottom: 10px;
}
.analytics-form .month-preset-buttons .btn {
  margin-right: 10px;
}
.analytics-form .month-preset-buttons .btn:last-child {
  margin-right: 0;
}
.analytics-form .analyticsForm-monthRow {
  display: flex;
  flex-flow: row nowrap;
}
.analytics-form .analyticsForm-monthRow > * {
  flex: 0 0 50%;
}
.analytics-form .analyticsForm-monthRow > *:first-child {
  padding-right: 10px;
}
.analytics-form .datetime-wrap span.glyphicon {
  left: auto;
  right: 10px !important;
}
.analytics-form .form-group.has-error .typeSelect__control,
.analytics-form .form-group.has-error .communityIdSelect__control {
  border-color: #c55337;
}

.analytics-wrap .empty-message h3 {
  padding: 0;
}

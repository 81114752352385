.centeredLoader {
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  -ms-flex-pack: center;
  -ms-flex-align: center;
}
.centeredLoader.centeredLoader--overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(247, 244, 236, 0.3);
  z-index: 2000;
}
.centeredLoader.centeredLoader--overlay--noOpacity {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #f7f4ec;
  z-index: 2000;
}

.centeredLoader-loader,
.centeredLoader-loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.centeredLoader-loader {
  margin: 60px 0;
  font-size: 4px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 0, 0, 0.2);
  border-right: 1.1em solid rgba(0, 0, 0, 0.2);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid rgba(0, 0, 0, 0.5);
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

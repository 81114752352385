/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.document .pageLayout-header {
  background: #656D78;
  padding-bottom: 15px;
  padding-top: 10px;
}

.pageLayout-content .document .pageLayout-header {
  background: #656D78;
  padding-top: 10px;
}

.document-header__title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.document-header__title .glyphicon-chevron-left {
  margin-right: 3px;
}

.document-header__titleText .btn.btn-link {
  color: rgba(255, 255, 255, 0.4);
  margin-left: -15px;
}
.document-header__titleText h1 {
  color: white;
}

.document-header__titleActions {
  margin-left: auto;
}
.document-header__titleActions > * {
  margin-left: 8px;
}

.document-content {
  padding: 15px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}
.document-content > * {
  flex: 1 1 auto;
}

.document-associations {
  margin-left: 15px;
  flex: 0 0 40%;
}
.document-associations h3 {
  margin: 0 0 10px;
  font-size: 22px;
}

.featuresTable-tableRow > td:last-child,
.featuresTable-tableRow > td:nth-last-child(2) {
  width: 1%;
  white-space: nowrap;
}
.featuresTable-tableRow > td.none {
  color: red !important;
  font-style: italic;
}
.featuresTable-tableRow > td.none a {
  color: inherit;
}
.featuresTable-tableRow > td.stewardships .label {
  margin-right: 5px;
}
.featuresTable-tableRow > td.stewardships .label:last-child {
  margin-right: 0;
}
.featuresTable-tableRow > td.visibility {
  text-align: center;
}
.featuresTable-tableRow .form-group,
.featuresTable-tableRow .checkbox {
  margin: 0;
}

.featuresTable--pointType {
  text-align: center;
  white-space: nowrap;
}

/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.redactor-box.redactor-styles-on {
  border-color: #aab2bd;
  border-radius: 4px;
}

.redactor-styles,
.redactor-dropdown,
.redactor-modal {
  color: #434A54;
  font-family: "Karla", sans-serif;
  font-size: 14px;
  line-height: 1.4em;
}

.redactor-toolbar a {
  height: 32px;
  transition: all 0.25s;
}

.redactor-toolbar a:hover {
  background: #E6E9ED;
  color: #434A54;
}

.redactor-modal-header {
  padding: 15px;
}

.redactor-modal-body {
  padding: 15px;
}

.redactor-modal .redactor-close {
  right: 10px;
  top: 10px;
}

.redactor-modal-footer {
  padding: 15px;
}
.redactor-modal-footer button {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #3bafda;
  border-color: transparent;
  color: #fff;
  -webkit-transition: all 0.15s;
  transition: all 0.15s;
  font-family: "Karla", sans-serif;
}
.redactor-modal-footer button.redactor-button-unstyled {
  background-color: #fff;
  border-color: #aab2bd !important;
  margin-right: 0 !important;
}
.redactor-modal-footer button.redactor-button-unstyled:hover {
  border-color: #ccd1d9;
  background-color: #ccd1d9;
  color: #fff;
  text-decoration: none;
}
.redactor-modal-footer button:hover {
  background-color: #4fc1e9;
  border-color: transparent;
  color: #fff;
}

.sidebarNavigation__links {
  list-style: none;
}

.sidebarNavigation__link {
  color: inherit;
  border-radius: 0;
  padding: 8px 12px;
  display: block;
  transition: all 0.25s;
}
.sidebarNavigation__link:hover {
  color: inherit;
  background: #333333;
  text-decoration: none;
}
.sidebarNavigation__link:focus {
  color: inherit;
  background: #333333;
  text-decoration: none;
}

.sidebarNavigation__link.active {
  background: #88B13D;
}
.sidebarNavigation__link.active:hover {
  background: #799e36;
}
.sidebarNavigation__link.active:focus {
  background: #799e36;
}

.sidebarNavigation__link.locations .label-container {
  float: right;
}
.sidebarNavigation__link.locations .active {
  background: #88B13D;
}
.sidebarNavigation__link.locations .active:focus {
  background: #799e36;
}
.sidebarNavigation__link.locations .active:hover {
  background: #799e36;
}

.sidebarNavigation__submenu .sidebarNavigation__submenu-link.active {
  background: #88B13D;
}
.sidebarNavigation__submenu .sidebarNavigation__submenu-link.active:focus {
  background: #799e36;
}
.sidebarNavigation__submenu .sidebarNavigation__submenu-link.active:hover {
  background: #799e36;
}
.sidebarNavigation__submenu .sidebarNavigation__submenu-links {
  background: rgba(0, 0, 0, 0.2);
}
.sidebarNavigation__submenu .sidebarNavigation__submenu-link {
  display: block;
  padding: 8px 12px;
  padding-left: 25px;
  color: white;
}
.sidebarNavigation__submenu .sidebarNavigation__submenu-link:focus {
  text-decoration: none;
  background: rgba(0, 0, 0, 0.2);
}
.sidebarNavigation__submenu .sidebarNavigation__submenu-link:hover {
  text-decoration: none;
  background: rgba(0, 0, 0, 0.2);
}
.sidebarNavigation__submenu .sidebarNavigation__submenu-link.active-child {
  background: #88B13D;
}

.sidebarNavigation__link.active-child {
  background: inherit;
}
.sidebarNavigation__link.active-child:hover {
  background: #333333;
}
.sidebarNavigation__link.active-child:focus {
  background: #333333;
}

.sidebarLayout-organizationDropdown {
  min-height: 50px;
  border-bottom: 1px solid #313132;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  padding: 9px 10px;
  position: relative;
  z-index: 999;
}
.sidebarLayout-organizationDropdown .organizationSingle-logo {
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 3px;
  margin-right: 10px;
  flex: 0 0 auto;
  background-position: center;
  background-size: contain;
  background-repeat: none;
}
.sidebarLayout-organizationDropdown .dropdown {
  max-width: 100%;
  width: 100%;
}
.sidebarLayout-organizationDropdown .dropdown-menu {
  max-height: 50vh;
  overflow-y: scroll;
}
.sidebarLayout-organizationDropdown #organization-dropdown {
  padding: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  position: relative;
  padding-right: 15px;
  min-height: 30px;
  width: 100%;
}
.sidebarLayout-organizationDropdown #organization-dropdown:hover {
  text-decoration: none;
}
.sidebarLayout-organizationDropdown #organization-dropdown:focus {
  text-decoration: none;
}
.sidebarLayout-organizationDropdown #organization-dropdown:active {
  text-decoration: none;
}
.sidebarLayout-organizationDropdown #organization-dropdown .caret {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  color: white;
}
.sidebarLayout-organizationDropdown .orgDropdown-button {
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
}
.sidebarLayout-organizationDropdown .orgDropdown-button .orgDropdown-button__title {
  display: inline-block;
  max-width: 120px;
  flex: 1 1 auto;
  min-width: 0;
  color: white;
  white-space: normal;
  text-align: left;
  line-height: 1.1em;
}

.sidebarLayout-organizationSingle {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
}
.sidebarLayout-organizationSingle h4 {
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  padding: 0 10px 0 0;
  flex: 1 1 auto;
  min-width: 0;
}

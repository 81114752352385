/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.paperMap {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 100%;
  overflow: hidden;
}

.paperMap-sidebar {
  background: #F5F7FA;
  border-right: 1px solid #E6E9ED;
  padding: 15px;
  width: 400px;
  overflow-y: auto;
}

.paperMap-sidebarActions {
  background: #AAB2BD;
  display: flex;
  flex-flow: row nowrap;
  margin: -15px -15px 15px;
}
.paperMap-sidebarActions .btn.btn-link {
  color: #434A54;
  font-weight: 600;
  margin-left: 4px;
}
.paperMap-sidebarActions .glyphicon-chevron-left {
  margin-right: 3px;
}

.paperMap-title .btn.btn-link {
  color: #434A54;
  margin-top: -5px;
  opacity: 0.5;
  padding-left: 0;
}
.paperMap-title h1 {
  font-size: 28px;
  line-height: 1.1em;
  margin: 0 0 10px;
}

.paperMap-relatedLocations {
  border-top: 1px solid #E6E9ED;
  margin-top: 15px;
  padding-top: 15px;
}

.paperMap-attributes {
  border-top: 1px solid #E6E9ED;
  margin-top: 5px;
  padding-top: 15px;
}
.paperMap-attributes label {
  display: block;
  margin-bottom: 2px;
}
.paperMap-attributes section + section {
  margin-top: 10px;
}

.paperMap-actions {
  border-top: 1px solid #E6E9ED;
  margin-top: 15px;
  padding-top: 15px;
}
.paperMap-actions .btn {
  display: block;
  margin-bottom: 10px;
  width: 100%;
}

.paperMap-share {
  border-top: 1px solid #E6E9ED;
  margin-top: 15px;
  padding-top: 15px;
}

.paperMap-embed {
  margin-top: 15px;
}
.paperMap-embed pre {
  word-break: break-word;
}

.paperMap-content {
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
}
.paperMap-content .mapPreview {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

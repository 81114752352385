.outerspatial {
  z-index: 1000;
}

.addWaypointPopup {
  padding: 20px;
}

.outingRoute--map {
  display: flex;
  flex-flow: column nowrap;
}
.outingRoute--map .panel-body {
  padding: 0px;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
}
.outingRoute--map .outingRoute-map__wrap {
  flex: 1 1 100%;
  display: flex;
  flex-flow: row nowrap;
}

.outingRoute-map__container {
  height: 500px;
  position: relative;
  flex: 1 1 auto;
}

.outingRoute-map__wrap .mapPreview {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.outing-wrap .mapsList-wrap {
  padding: 15px;
}

.outingRoute-mapActions {
  margin-top: 10px;
}
.outingRoute-mapActions > div {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.outingRoute-mapActions p {
  margin: 0;
}

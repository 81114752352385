/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.os-checkbox {
  display: inline-block;
  padding: 5px;
}
.os-checkbox .os-checkbox-box {
  display: block;
  height: 16px;
  width: 16px;
  background: white;
  border: 1px solid #E6E9ED;
}
.os-checkbox .glyphicon {
  color: #434A54;
  font-size: 12px;
  line-height: 1em;
  display: inline-block;
  transform: translate(1px, -3px);
}

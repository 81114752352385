.welcomeModal-body {
  background-color: #fffdfa;
  padding: 15px;
}

.welcomeModal-body .alert-info {
  background-color: #d9edf7;
  border: #bce8f1;
}

.welcomeModal-body ul {
  margin: 15px 0;
  padding: 0;
}

.welcomeModal-body li {
  background: #ffffff;
  border: solid 1px #6d6e70;
  border-radius: 14px;
  clear: left;
  display: block;
  margin-bottom: 12px;
  min-height: 78px;
  padding: 14px;
  width: 100%;
}

.welcomeModal-body li img {
  height: 50px;
  width: 50px;
}

.welcomeModal-body li div {
  float: left;
  width: 50%;
}

.welcomeModal-body li div:last-child {
  text-align: right;
}

.welcomeModal-body li .btn-default {
  border-color: #44a6cf !important;
  color: #44A6CF;
  font-weight: bold;
  margin-top: 7px;
}

.BeaconContainer {
  z-index: 9999999999 !important;
}

.BeaconFabButtonFrame {
  z-index: 9999999998 !important;
}

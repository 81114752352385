/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.organizationImports-titleBar {
  margin-bottom: 15px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.organizationImports-titleBar .organizationImports-actions {
  margin-left: auto;
}

.import-wrap .pageLayout-header {
  background: #656D78;
  padding-bottom: 10px;
  padding-top: 10px;
}
.import-wrap .panel-body > :last-child {
  margin-bottom: 0;
}

.import-header__title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.import-header__title .glyphicon-chevron-left {
  margin-right: 3px;
}

.import-header__titleText .btn.btn-link {
  color: rgba(255, 255, 255, 0.4);
  margin-left: -15px;
}
.import-header__titleText h1 {
  color: white;
}

.organizationImports-content .empty-message h3 {
  padding: 50px 30px;
  text-align: center;
}
.organizationImports-content .table td {
  vertical-align: middle;
}
.organizationImports-content .table td.centered,
.organizationImports-content .table th.centered {
  text-align: center;
}
.organizationImports-content .table td.nowrap {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.organizationImports-content .table td.actions {
  float: right;
}

.organization-wrap .uploader-close.btn {
  color: #6d6e70;
  font-size: 16px;
  position: absolute;
  right: 10px;
  top: 10px;
}
.organization-wrap .uploader-container-inner {
  text-align: center;
}
.organization-wrap .uploader-container-inner .btn-default {
  margin-bottom: 20px;
}

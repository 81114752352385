/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.heroItem-wrap .pageLayout-header {
  background: #656D78;
  padding-bottom: 15px;
  padding-top: 10px;
}
.heroItem-wrap .heroItemInfo-alert {
  border-radius: 0;
}
.heroItem-wrap .heroItemInfo-alert span {
  display: block;
  margin: 2px 0;
  line-height: 1.2em;
}

.heroItem-header__title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.heroItem-header__title .glyphicon-chevron-left {
  margin-right: 3px;
}

.heroItem-header__titleText .btn.btn-link {
  color: rgba(255, 255, 255, 0.4);
  margin-left: -15px;
}
.heroItem-header__titleText h1 {
  color: white;
}

.heroItem-header__titleActions {
  margin-top: 10px;
  margin-left: auto;
}

.heroItemInfo-actions {
  margin-bottom: 20px;
  display: flex;
  flex-flow: row nowrap;
}
.heroItemInfo-actions .btn {
  margin-left: auto;
}

.heroItemInfo-wrap .panel {
  position: relative;
}
.heroItemInfo-wrap .panel-header {
  padding-bottom: 15px;
}
.heroItemInfo-wrap .panel-body .panel-body {
  border-top: 1px solid #E6E9ED;
  background: #F5F7FA;
  margin: 0 -15px -15px;
  border-radius: 0 0 4px 4px;
}
.heroItemInfo-wrap .error-message {
  color: #c55337;
  display: none;
}
.heroItemInfo-wrap .form-group.has-error .error-message {
  display: inherit;
}
.heroItemInfo-wrap .form-group.has-error .heroItemSection-image {
  border-color: #c55337 !important;
  margin-bottom: 0;
}

.heroItemSection-image {
  display: block;
  position: relative;
  height: 150px;
  width: 250px;
  border: 1px solid #AAB2BD;
  border-radius: 3px;
  overflow: hidden;
  background: #F8f4ec;
  margin-bottom: 15px;
}
.heroItemSection-image.heroItemImage--hasLogo .heroItemImage-imageButton {
  opacity: 1;
}
.heroItemSection-image .heroItemImage-imageButton {
  bottom: 10px;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  width: 100px;
}
.heroItemSection-image .heroItemImage-imageButton:hover {
  background: white;
  color: #434A54;
}
.heroItemSection-image .heroItemSection-bannerImage {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.heroItemsActionBar-wrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 20px;
}
.heroItemsActionBar-wrap .form-group {
  margin: 0;
}

.heroItemsActionBar-actionsRight {
  margin-left: auto;
}
.heroItemsActionBar-actionsRight > * {
  margin-left: 8px;
}

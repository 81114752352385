/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.postForm .nav {
  border-bottom: 1px solid #E6E9ED;
}

.postForm-form {
  margin-top: 15px;
  padding: 0 15px;
}

.postForm-settingsRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
}
.postForm-settingsRow > * {
  flex: 0 0 auto;
}

.postForm-date {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
}
.postForm-date > * {
  margin-right: 8px;
}
.postForm-date .postForm-date--select {
  min-width: 110px;
}

.postForm-color {
  width: 40%;
  padding-left: 8px;
}

.postForm-gallery {
  display: flex;
  margin: 15px -5px;
}

.postForm-image {
  display: block;
  height: 80px;
  width: 80px;
  background-size: cover;
  background-position: center center;
  border-radius: 2px;
  margin: 5px;
  position: relative;
}
.postForm-image .postForm-image__actions {
  position: absolute;
  top: 0;
  right: 0;
}
.postForm-image .postForm-image__actions .btn {
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

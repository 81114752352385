/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.react-joyride__tooltip iframe {
  border: solid 1px #e0e0e0;
}

.dashboardActionBar-wrap {
  align-items: flex-start;
  display: flex;
  flex-flow: row nowrap;
}

.dashboardActionBar-actions {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 10px;
  margin-left: auto;
}
.dashboardActionBar-actions .btn {
  margin-left: 8px;
}

.dashboard {
  display: flex;
  flex: 2 1 100%;
  flex-flow: column nowrap;
}
.dashboard .carousel .item a.image-wrapper {
  background-color: #3e3e3f;
  display: block;
}
.dashboard .carousel .item img {
  margin: auto;
  max-height: 177px;
}
.dashboard .carousel-control {
  height: 177px;
}
.dashboard .carousel-loading {
  height: 237px;
}
.dashboard .carousel-control.left {
  background-image: none;
}
.dashboard .carousel-control.right {
  background-image: none;
}
.dashboard .carousel-inner {
  height: 177px;
}
.dashboard .carousel-inner button {
  background-color: transparent;
  border: none;
  padding: 0;
}
.dashboard .carousel-inner button.image-link {
  display: block;
  height: 177px;
}
.dashboard .carousel-inner button.image-link img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.dashboard .carousel-inner div.wrap {
  height: 60px;
}
.dashboard .carousel-inner div.wrap div.top {
  clear: both;
  margin-bottom: 6px;
  margin-top: 8px;
}
.dashboard .carousel-inner div.wrap div.type {
  color: #9a9b9e;
  float: left;
  font-size: 12px;
  text-transform: uppercase;
}
.dashboard .carousel-inner div.wrap div.date {
  color: #9a9b9e;
  float: right;
  font-size: 12px;
  text-align: right;
}
.dashboard .carousel-inner div.wrap a,
.dashboard .carousel-inner div.wrap a:hover {
  color: #434A54;
}
.dashboard .carousel-inner div.wrap h3 {
  font-family: "PT Sans Narrow", sans-serif;
  font-size: 18px;
  margin-bottom: 0;
  margin-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dashboard .container-fluid {
  padding: 0;
  width: 100%;
}
.dashboard .container-fluid .pageLayout,
.dashboard .container-fluid .pageLayout-content {
  -webkit-flex: 2 1 100%;
  -webkit-flex-flow: column nowrap;
  display: -webkit-flex;
  display: flex;
  flex-flow: column nowrap;
  flex: 2 1 100%;
  padding: 0;
}
.dashboard .container-fluid .pageLayout-header {
  padding-bottom: 15px;
}
.dashboard .container-fluid .pageLayout-content {
  position: relative;
}
.dashboard .container-fluid .col-md-4 {
  padding-left: 0;
}
.dashboard .container-fluid .panel-group .panel {
  border-bottom: solid 1px #e1e2e2;
}
.dashboard .container-fluid .panel-group .panel:last-child {
  border-bottom: none;
}
.dashboard .container-fluid .panel-default .panel-group .panel-title {
  font-family: "Karla", sans-serif;
  font-size: 16px;
  font-weight: bold;
}
.dashboard .container-fluid .panel-default .panel-title {
  font-family: "PT Sans Narrow", sans-serif;
  font-size: 18px;
}
.dashboard .container-fluid .panel-default .panel-title p {
  font-family: "Karla", sans-serif;
  font-size: 12px;
  margin-bottom: 0;
  margin-top: 5px;
}
.dashboard .container-fluid .panel-group .panel-heading + .panel-collapse .panel-body {
  padding: 0;
}
.dashboard .container-fluid .panel-group .panel-heading + .panel-collapse .panel-body .table {
  margin-bottom: 0;
}
.dashboard .container-fluid .panel-group .panel-heading,
.dashboard .container-fluid .panel .panel-heading {
  padding: 15px 15px;
}
.dashboard .container-fluid .panel-group .panel-heading a[aria-expanded=true] span.rotate,
.dashboard .container-fluid .panel .panel-heading a[aria-expanded=true] span.rotate {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.dashboard .container-fluid .panel-group .panel-heading a .rotate,
.dashboard .container-fluid .panel .panel-heading a .rotate {
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  color: #cccccc;
  margin-left: 10px;
  transform: rotate(0deg);
}
.dashboard .container-fluid .panel-group .panel-title a:hover {
  color: #434A54;
  text-decoration: none;
}
.dashboard .container-fluid .panel-group .panel-title a:focus {
  text-decoration: none;
}

.dashboard-header__title {
  display: flex;
  flex-flow: row nowrap;
}

.checklist .btn.btn-link {
  padding: 0;
}
.checklist .panel-body {
  padding: 0;
}
.checklist .panel-group {
  margin-bottom: 0;
}
.checklist tr {
  background-color: #f9f9f9;
}
.checklist td {
  vertical-align: middle;
}
.checklist td p {
  margin-bottom: 0;
}
.checklist td:first-child {
  padding-left: 15px;
}
.checklist td:first-child div:first-child {
  padding-top: 7.5px;
}
.checklist td:first-child div:last-child {
  padding-bottom: 7.5px;
}
.checklist td:last-child {
  padding-right: 15px;
  vertical-align: middle;
}

/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.eventForm-wrap .panel {
  position: relative;
}

.eventForm-content {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}
.eventForm-content > * {
  flex: 1 1 auto;
}

.eventForm-associations {
  margin-left: 15px;
  flex: 0 0 40%;
}

.eventForm-dateRow {
  display: flex;
  flex-flow: row nowrap;
}
.eventForm-dateRow > * {
  flex: 0 0 33.33%;
}
.eventForm-dateRow > *:first-child {
  padding-right: 10px;
}
.eventForm-dateRow > *:last-child {
  padding-left: 10px;
}

.eventForm-locationRow__content {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 15px;
}

.eventForm-locationSelect {
  flex: 0 0 auto;
  min-width: 120px;
}

.eventForm-locationRow__field {
  padding-left: 10px;
  flex: 1 1 auto;
}
.eventForm-locationRow__field .form-group {
  margin: 0;
}

.eventForm-featuredImage {
  display: block;
  position: relative;
  height: 150px;
  width: 250px;
  border: 1px solid #AAB2BD;
  border-radius: 3px;
  overflow: hidden;
  background: #F8f4ec;
  margin-bottom: 15px;
}
.eventForm-featuredImage.featuredImage--hasLogo .eventForm-imageButton {
  opacity: 1;
}
.eventForm-featuredImage .eventForm-featuredImage__controls label {
  display: block;
}
.eventForm-featuredImage .eventForm-featuredImage__controls .btn {
  display: block;
}
.eventForm-featuredImage .control-label {
  margin-right: 10px;
  display: inline-block;
}
.eventForm-featuredImage .eventForm-imageButton {
  bottom: 10px;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  width: 100px;
}
.eventForm-featuredImage .eventForm-imageButton:hover {
  background: white;
  color: #434A54;
}
.eventForm-featuredImage .eventForm-image {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

@import "src/config.scss";

.commentForm {
  border-top: 1px solid $border-light;
  padding: 16px;
  position: relative;
  overflow: hidden;

  .form-group {
    width: 100%;
    margin: 0;
  }

  form {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;

    .btn { margin-left: 16px; }
  }
}
/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.organizationForm__actions {
  margin-bottom: 20px;
}

.organizationInfo-content {
  position: relative;
}
.organizationInfo-content .disabledWysiwygContent {
  border-color: #E6E9ED;
  background-color: #E6E9ED;
  cursor: not-allowed;
  padding: 6px 12px;
}
.organizationInfo-content .form-group {
  margin-bottom: 5px;
}
.organizationInfo-content .panel-heading {
  height: 55px;
}
.organizationInfo-content .panel-heading .btn-group {
  margin-right: 5px;
}

.organizationInfo-logo {
  background: white;
  display: block;
  overflow: hidden;
  position: relative;
}
.organizationInfo-logo .control-label {
  margin-right: 10px;
  display: inline-block;
}
.organizationInfo-logo .organizationInfo-logoImage {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #AAB2BD;
  border-radius: 3px;
  height: 152px;
  width: 152px;
}
.organizationInfo-logo .organizationInfo-logoButton {
  margin-left: 26px;
  margin-top: 10px;
  width: 100px;
}

.organizationInfo-logo-editor {
  border: 1px solid #AAB2BD;
  border-radius: 3px;
}

/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.outing-wrap {
  display: flex;
  flex: 2 1 100%;
  flex-flow: column nowrap;
}
.outing-wrap .feature-header .visibilityDropdown.open > .dropdown-menu {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.6);
  z-index: 10000;
}
.outing-wrap .featureBuilderMap,
.outing-wrap .outingRoute-content,
.outing-wrap .outingRoute-map__container,
.outing-wrap .outingRoute-map__wrap,
.outing-wrap .pageLayout,
.outing-wrap .pageLayout-content {
  display: flex;
  flex-flow: column nowrap;
  flex: 2 1 100%;
  padding: 0px;
}
.outing-wrap .featureBuilderMap {
  margin: 0;
}
.outing-wrap .featureMedia-wrap,
.outing-wrap .outingInfo-wrap {
  padding: 15px;
}
.outing-wrap .featureMedia--empty .mediaActionBar-actionsRight .btn {
  font-size: 14px;
  padding: 6px 12px;
}
.outing-wrap .pageLayout-content {
  position: relative;
}
.outing-wrap .featurePosts {
  margin: 0;
}
.outing-wrap .featurePosts .posts-header {
  padding-bottom: 15px;
}
.outing-wrap .error-message {
  color: #c55337;
}

.outing-contentWrap .outing-featureImage {
  width: 100%;
}

.waypoint-info .waypoint-image {
  background: #f8f4ec;
  border: 1px solid #aab2bd;
  border-radius: 3px;
  display: block;
  height: 150px;
  margin-bottom: 15px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.waypoint-info .waypoint-imageButton {
  bottom: 10px;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  width: 100px;
  z-index: 5;
}
.waypoint-info .waypoint-imageButton:hover {
  background: white;
  color: #434A54;
}
.waypoint-info .waypoint-bannerImage {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.waypoint-locationInfo {
  margin-bottom: 10px;
}
.waypoint-locationInfo .waypoint-locationDescription {
  margin-top: 15px;
}
.waypoint-locationInfo .waypoint-locationName {
  font-family: "PT Sans Narrow", sans-serif;
  font-size: 20px;
}

.waypointInfo-help {
  background-color: #E4F2C9;
  color: #4F5F32;
  padding: 14px;
}

.outing-content {
  display: flex;
  flex-flow: column nowrap;
  padding: 15px;
}
.outing-content > * {
  flex: 2 1 100%;
}

.outing-associations {
  flex: 0 0 40%;
  margin-left: 15px;
}
.outing-associations h3 {
  font-size: 22px;
  margin: 0 0 10px;
}

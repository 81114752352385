/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.featurePosts {
  flex: 1 1 100%;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  margin: -15px;
  overflow-x: hidden;
}
.featurePosts .pageLayout-header {
  background: #F5F7FA;
}
.featurePosts .postItems {
  max-width: 600px;
}

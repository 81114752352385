/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.eventView-header__titleActions {
  margin-left: auto;
}
.eventView-header__titleActions .btn {
  margin-left: 8px;
}

.eventView-wrap .pageLayout-content {
  padding: 15px;
}
.eventView-wrap .pageLayout-header {
  background: #656D78;
  padding-bottom: 15px;
  padding-top: 10px;
}

.eventView-header__title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.eventView-header__title .glyphicon-chevron-left {
  margin-right: 3px;
}

.eventView-header__titleText .btn.btn-link {
  color: rgba(255, 255, 255, 0.4);
  margin-left: -15px;
}
.eventView-header__titleText h1 {
  color: white;
}

/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.mediaChooser-wrap {
  display: flex;
  flex-flow: row wrap;
}

.mediaChooser-item {
  flex: 0 0 120px;
  padding: 0px;
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: #D8D8D8;
  position: relative;
  overflow: hidden;
  border: solid 1px #D8D8D8;
  border-radius: 5px;
  cursor: pointer;
}
.mediaChooser-item button.mediaChooser-itemRemove {
  height: 30px;
  width: 30px;
  position: absolute;
  color: white;
  top: 0px;
  right: 0px;
  background-color: rgba(66, 66, 66, 0.7019607843);
}
.mediaChooser-item .mediaChooser-itemImage {
  background-size: cover;
  background-position: center center;
  width: 120px;
  height: 120px;
  flex: 1 0 120px;
  display: flex;
  justify-content: center;
}
.mediaChooser-item .mediaChooser-itemCaption {
  flex: 1 0 120px;
  padding: 3px 5px;
  background-color: #EBEBEB;
  text-align: center;
}
.mediaChooser-item:hover .mediaChooser-item__buttons {
  opacity: 1;
}
.mediaChooser-item.mediaChooser-listItem__upload {
  display: flex;
}
.mediaChooser-item.mediaItem--selected {
  box-shadow: 0 0 0 3px rgba(125, 168, 54, 0.85);
}

.mediaChooser-item__title {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  font-size: 12px;
  line-height: 1em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  padding: 5px 5px 3px;
}

.mediaChooser-item__select {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
}
.mediaChooser-item__select .checkbox {
  margin: 0;
}
.mediaChooser-item__select .checkbox input[type=checkbox] {
  position: absolute;
  top: 5px;
  left: 5px;
  margin: 0;
}
.mediaChooser-item__select .checkbox,
.mediaChooser-item__select label {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.mediaChooser-item__captionWarning {
  position: absolute;
  top: 4px;
  right: 6px;
  color: #FFCE54;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.mediaChooser-item__buttons {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  transition: opacity 0.25s 0.1s;
  opacity: 0;
  background: rgba(255, 255, 255, 0.3);
}
.mediaChooser-item__buttons .btn {
  color: white;
  font-size: 20px;
  opacity: 0.8;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);
}
.mediaChooser-item__buttons .btn:hover {
  color: white;
}

.mediaList-listItem.mediaList-listItem__upload {
  padding: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: #ccc;
  border: 5px solid #F8f4ec;
  border-radius: 2px;
}
.mediaList-listItem.mediaList-listItem__upload .progress {
  margin: 0;
  flex: 0 0 auto;
  width: 100%;
}

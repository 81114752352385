.organizationMembershipRequests h4 {
  padding-top: 15px;
  padding-bottom: 10px;
}

.organizationMembers-titleBar {
  margin-bottom: 15px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.organizationMembers-actions {
  margin-left: auto;
}

.organizationMember__title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.organizationMember__title h3 {
  margin: 0;
}

.organizationMember__actions {
  margin-left: auto;
}

.organizationMembers-list .table > tbody > tr > td {
  vertical-align: middle;
}
.organizationMembers-list .table > tbody > tr > td.none {
  color: red !important;
  font-style: italic;
}
.organizationMembers-list .label {
  margin-left: 5px;
}
.organizationMembers-list .organizationMembers--actions {
  text-align: right;
}

@import url("https://fonts.googleapis.com/css?family=Karla:400,400i,700|PT+Sans+Narrow:400,700");
/*!
 * https://github.com/YouCanBookMe/react-datetime
 */
.rdt {
  position: relative;
}

.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f9f9f9;
}

.rdtOpen .rdtPicker {
  display: block;
}

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}

.rdtPicker td {
  cursor: pointer;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}

.rdtPicker td.rdtToday {
  position: relative;
}

.rdtPicker td.rdtToday:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
}

.rdtPicker th.rdtSwitch {
  width: 100px;
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker thead tr:first-child th {
  cursor: pointer;
}

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}

.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.rdtCounter .rdtBtn:hover {
  background: #eee;
}

.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

/**
 * button
 * --------------------------------------------------
 */
.btn {
  color: #FFF;
}
.btn, .btn.disabled, .btn[disabled] {
  border-color: #AAB2BD;
  background-color: #AAB2BD;
}
.btn:hover, .btn:focus, .btn:active, .btn.active {
  color: #FFF;
  border-color: #CCD1D9;
  background-color: #CCD1D9;
  outline: none !important;
}
.btn:active, .btn.active {
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.125);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.125);
}
.btn.disabled, .btn[disabled] {
  opacity: 0.45;
  filter: alpha(opacity=45);
}
.btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active, .btn-link.active, .btn-link.disabled, .btn-link[disabled] {
  border-color: transparent;
  background-color: transparent;
  color: #3BAFDA;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.btn-link:hover, .btn-link:focus {
  text-decoration: underline;
}

.btn-default {
  color: #434A54;
  border-color: #AAB2BD !important;
}
.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active {
  border-color: #CCD1D9;
  background-color: #CCD1D9;
}
.btn-default, .btn-default.disabled, .btn-default[disabled] {
  background-color: #FFF;
}

.open .dropdown-toggle.btn-default {
  border-color: #CCD1D9;
  background-color: #CCD1D9;
}

.btn-primary, .btn-primary:active, .btn-primary.active, .btn-primary.disabled, .btn-primary[disabled] {
  border-color: #3BAFDA;
  background-color: #3BAFDA;
}
.btn-primary:hover, .btn-primary:focus {
  border-color: #4FC1E9;
  background-color: #4FC1E9;
}

.open .dropdown-toggle.btn-primary {
  border-color: #4FC1E9;
  background-color: #4FC1E9;
}

.btn-info, .btn-info:active, .btn-info.active, .btn-info.disabled, .btn-info[disabled] {
  border-color: #37BC9B;
  background-color: #37BC9B;
}
.btn-info:hover, .btn-info:focus {
  border-color: #48CFAD;
  background-color: #48CFAD;
}

.open .dropdown-toggle.btn-info {
  border-color: #48CFAD;
  background-color: #48CFAD;
}

.btn-success, .btn-success:active, .btn-success.active, .btn-success.disabled, .btn-success[disabled] {
  border-color: #8CC152;
  background-color: #8CC152;
}
.btn-success:hover, .btn-success:focus {
  border-color: #A0D468;
  background-color: #A0D468;
}

.open .dropdown-toggle.btn-success {
  border-color: #A0D468;
  background-color: #A0D468;
}

.btn-warning, .btn-warning:active, .btn-warning.active, .btn-warning.disabled, .btn-warning[disabled] {
  border-color: #F6BB42;
  background-color: #F6BB42;
}
.btn-warning:hover, .btn-warning:focus {
  border-color: #FFCE54;
  background-color: #FFCE54;
}

.open .dropdown-toggle.btn-warning {
  border-color: #FFCE54;
  background-color: #FFCE54;
}

.btn-danger, .btn-danger:active, .btn-danger.active, .btn-danger.disabled, .btn-danger[disabled],
.btn-danger .open .dropdown-toggle.btn {
  border-color: #DA4453;
  background-color: #DA4453;
}
.btn-danger:hover, .btn-danger:focus {
  border-color: #ED5565;
  background-color: #ED5565;
}

.open .dropdown-toggle.btn-danger {
  border-color: #ED5565;
  background-color: #ED5565;
}

/**
 * button-group
 * --------------------------------------------------
 */
.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.125);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.125);
}

.btn-group .btn {
  border-left-color: #96a0ad;
}
.btn-group.open .btn-default.dropdown-toggle, .btn-group .btn-default:focus, .btn-group .btn-default:active, .btn-group .btn-default.active {
  color: #FFF;
}

.btn-group .btn-primary, .btn-group .btn-primary:focus, .btn-group .btn-primary:active, .btn-group .btn-primary.active {
  border-left-color: #269ecb;
}
.btn-group .btn-success, .btn-group .btn-success:focus, .btn-group .btn-success:active, .btn-group .btn-success.active {
  border-left-color: #7ab03f;
}
.btn-group .btn-warning, .btn-group .btn-warning:focus, .btn-group .btn-warning:active, .btn-group .btn-warning.active {
  border-left-color: #efa50b;
}
.btn-group .btn-danger, .btn-group .btn-danger:focus, .btn-group .btn-danger:active, .btn-group .btn-danger.active {
  border-left-color: #d1293a;
}
.btn-group .btn-info, .btn-group .btn-info:focus, .btn-group .btn-info:active, .btn-group .btn-info.active {
  border-left-color: #2fa084;
}
.btn-group .btn:first-child, .btn-group .btn-primary:first-child, .btn-group .btn-success:first-child, .btn-group .btn-warning:first-child, .btn-group .btn-danger:first-child, .btn-group .btn-info:first-child {
  border-left-color: transparent;
}

.btn-group-vertical .btn, .btn-group-vertical .btn-group .btn-primary {
  border-top-color: #96a0ad !important;
}
.btn-group-vertical .btn-primary, .btn-group-vertical .btn-primary:focus, .btn-group-vertical .btn-primary:active, .btn-group-vertical .btn-primary.active, .btn-group-vertical .btn-group .btn-primary {
  border-top-color: #269ecb !important;
}
.btn-group-vertical .btn-success, .btn-group-vertical .btn-success:focus, .btn-group-vertical .btn-success:active, .btn-group-vertical .btn-success.active, .btn-group-vertical .btn-group .btn-success {
  border-top-color: #7ab03f !important;
}
.btn-group-vertical .btn-warning, .btn-group-vertical .btn-warning:focus, .btn-group-vertical .btn-warning:active, .btn-group-vertical .btn-warning.active, .btn-group-vertical .btn-group .btn-warning {
  border-top-color: #efa50b !important;
}
.btn-group-vertical .btn-danger, .btn-group-vertical .btn-danger:focus, .btn-group-vertical .btn-danger:active, .btn-group-vertical .btn-danger.active, .btn-group-vertical .btn-group .btn-danger {
  border-top-color: #d1293a !important;
}
.btn-group-vertical .btn-info, .btn-group-vertical .btn-info:focus, .btn-group-vertical .btn-info:active, .btn-group-vertical .btn-info.active, .btn-group-vertical .btn-group .btn-info {
  border-top-color: #2fa084 !important;
}
.btn-group-vertical .btn:not(.btn-default):first-child, .btn-group-vertical .btn-primary:first-child, .btn-group-vertical .btn-success:first-child, .btn-group-vertical .btn-warning:first-child, .btn-group-vertical .btn-danger:first-child, .btn-group-vertical .btn-info:first-child {
  border-top: none;
}

/**
 * labels and badges
 * --------------------------------------------------
 */
.label,
.badge {
  background-color: #AAB2BD;
}

.label-default,
.badge-default {
  border: 1px solid #AAB2BD;
  background-color: #FFF;
  color: #434A54;
}

.label-primary,
.badge-primary {
  border-color: #3BAFDA;
  background-color: #3BAFDA;
}

.label-success,
.badge-success {
  border-color: #8CC152;
  background-color: #8CC152;
}

.label-danger,
.badge-danger {
  border-color: #DA4453;
  background-color: #DA4453;
}

.label-warning,
.badge-warning {
  border-color: #F6BB42;
  background-color: #F6BB42;
}

.label-info,
.badge-info {
  border-color: #37BC9B;
  background-color: #37BC9B;
}

/**
 * tooltip
 * --------------------------------------------------
 */
.tooltip-inner {
  color: #FFF;
  background-color: #434A54;
}

.tooltip.top .tooltip-arrow, .tooltip.top-left .tooltip-arrow, .tooltip.top-right .tooltip-arrow {
  border-top-color: #434A54;
}
.tooltip.right .tooltip-arrow {
  border-right-color: #434A54;
}
.tooltip.left .tooltip-arrow {
  border-left-color: #434A54;
}
.tooltip.bottom .tooltip-arrow, .tooltip.bottom-left .tooltip-arrow, .tooltip.bottom-right .tooltip-arrow {
  border-bottom-color: #434A54;
}

/**
 * popover
 * --------------------------------------------------
 */
.popover {
  background-color: #434A54;
  color: #FFF;
  border-color: #434A54;
}
.popover-title {
  padding-bottom: 0;
  font-weight: bold;
  color: #AAB2BD;
  background-color: transparent;
  border-bottom: none;
}

.popover.top .arrow, .popover.top .arrow:after {
  border-top-color: #434A54;
}
.popover.right .arrow, .popover.right .arrow:after {
  border-right-color: #434A54;
}
.popover.bottom .arrow, .popover.bottom .arrow:after {
  border-bottom-color: #434A54;
}
.popover.left .arrow, .popover.left .arrow:after {
  border-left-color: #434A54;
}

/**
 * progress
 * --------------------------------------------------
 */
.progress {
  background-color: #E6E9ED;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.progress-bar {
  background-color: #3BAFDA;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.progress-bar-success {
  background-color: #8CC152;
}
.progress-bar-info {
  background-color: #37BC9B;
}
.progress-bar-warning {
  background-color: #F6BB42;
}
.progress-bar-danger {
  background-color: #DA4453;
}

/**
 * breadcrumb
 * --------------------------------------------------
 */
.breadcrumb {
  color: #434A54;
  background-color: #E6E9ED;
}
.breadcrumb > .active {
  color: #434A54;
}
.breadcrumb a {
  color: #3BAFDA;
}

.breadcrumb-arrow {
  padding: 0;
  list-style: none;
  background-color: #E6E9ED;
  height: 36px;
  line-height: 36px;
}
.breadcrumb-arrow li:first-child a {
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.breadcrumb-arrow li, .breadcrumb-arrow li a, .breadcrumb-arrow li span {
  display: inline-block;
  vertical-align: top;
}
.breadcrumb-arrow li:not(:first-child) {
  margin-left: -5px;
}
.breadcrumb-arrow li + li:before {
  padding: 0;
  content: "";
}
.breadcrumb-arrow li span {
  padding: 0 10px;
}
.breadcrumb-arrow li a, .breadcrumb-arrow li:not(:first-child) span {
  padding: 0 10px 0 25px;
  height: 36px;
  line-height: 36px;
}
.breadcrumb-arrow li:first-child a {
  padding: 0 10px;
}
.breadcrumb-arrow li a {
  position: relative;
  border: 1px solid #3BAFDA;
  color: #FFF;
  background-color: #3BAFDA;
  text-decoration: none;
}
.breadcrumb-arrow li:first-child a {
  padding-left: 10px;
}
.breadcrumb-arrow li a:before, .breadcrumb-arrow li a:after {
  position: absolute;
  top: -1px;
  content: "";
  width: 0;
  height: 0;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
}
.breadcrumb-arrow li a:before {
  right: -10px;
  border-left-width: 11px;
  border-left-style: solid;
  border-left-color: #3BAFDA;
  z-index: 3;
}
.breadcrumb-arrow li a:after {
  right: -11px;
  border-left: 11px solid #2494be;
  z-index: 2;
}
.breadcrumb-arrow li a:hover, .breadcrumb-arrow li a:focus {
  background-color: #4FC1E9;
  border: 1px solid #4FC1E9;
}
.breadcrumb-arrow li a:hover:before, .breadcrumb-arrow li a:focus:before {
  border-left-color: #4FC1E9;
}
.breadcrumb-arrow li a:active {
  background-color: #2494be;
  border: 1px solid #2494be;
}
.breadcrumb-arrow li a:active:before, .breadcrumb-arrow li a:active:after {
  border-left-color: #2494be;
}
.breadcrumb-arrow li span {
  color: #434A54;
}

/**
 * pagination
 * --------------------------------------------------
 */
.pagination > li > a, .pagination > li > span {
  color: #434A54;
  background-color: #FFF;
  border-color: #CCD1D9;
}
.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
  color: #FFF;
  background-color: #CCD1D9;
  border-color: #CCD1D9;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  color: #FFF;
  background-color: #8CC152;
  border-color: #8CC152;
}
.pagination > .disabled > span, .pagination > .disabled > span:hover, .pagination > .disabled > span:focus, .pagination > .disabled > a, .pagination > .disabled > a:hover, .pagination > .disabled > a:focus {
  color: #E6E9ED;
  background-color: #FFF;
  border-color: #CCD1D9;
}

/**
 * pager
 * --------------------------------------------------
 */
.pager li > a, .pager li > span {
  color: #FFF;
  background-color: #8CC152;
  border-color: #8CC152;
}
.pager li > a:hover, .pager li > a:focus {
  background-color: #A0D468;
  border-color: #A0D468;
}
.pager .disabled > a, .pager .disabled > a:hover, .pager .disabled > a:focus, .pager .disabled > span {
  color: #E6E9ED;
  background-color: #FFF;
  border-color: #E6E9ED;
}

/**
 * form
 * --------------------------------------------------
 */
.form-control {
  color: #434A54;
  border-color: #AAB2BD;
}
.form-control, .form-control:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.form-control:focus {
  border-color: #3BAFDA;
}
.form-control::-moz-placeholder, .form-control:-ms-input-placeholder, .form-control::-webkit-input-placeholder {
  color: #E6E9ED;
}
.form-control.disabled, .form-control[disabled] {
  border-color: #E6E9ED;
  background-color: #E6E9ED;
}

.input-group-btn .btn + .btn {
  border-width: 1px;
  border-style: solid;
  border-color: #96a0ad;
}
.input-group-btn .btn + .btn.btn-default {
  border-color: #ededed;
}
.input-group-btn .btn + .btn.btn-primary {
  border-color: #269ecb;
}
.input-group-btn .btn + .btn.btn-info {
  border-color: #2fa084;
}
.input-group-btn .btn + .btn.btn-success {
  border-color: #7ab03f;
}
.input-group-btn .btn + .btn.btn-warning {
  border-color: #f4af20;
}
.input-group-btn .btn + .btn.btn-danger {
  border-color: #d1293a;
}

.input-group-addon {
  background-color: #AAB2BD;
  border-color: #96a0ad;
  color: #FFF;
}
.input-group-addon .radio, .input-group-addon .checkbox {
  margin: -3px 0 -4px !important;
}

.form-search .search-query, .form-search .search-query:first-child, .form-search .search-query:last-child {
  padding: 0 17px;
  -webkit-border-radius: 17px;
  -moz-border-radius: 17px;
  border-radius: 17px;
}
.input-group .form-control:last-child {
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
}

.input-group .form-control:first-child {
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topright: 0;
  border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
}

.form-search .btn {
  -webkit-border-radius: 17px;
  -moz-border-radius: 17px;
  border-radius: 17px;
}

.search-only {
  position: relative;
}
.search-only .search-icon {
  position: absolute;
  top: 2px;
  left: 8.5px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 17px;
  color: #E6E9ED;
  z-index: 20;
}
.search-only .form-control:last-child {
  padding-left: 40px;
}

.has-success .help-block, .has-success .control-label, .has-success .radio, .has-success .checkbox, .has-success .radio-inline, .has-success .checkbox-inline {
  color: #8CC152;
}
.has-success .form-control {
  border-color: #8CC152;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.has-success .form-control:focus {
  border-color: #8CC152;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.has-success .input-group-addon {
  background-color: #8CC152;
  border-color: #8CC152;
}
.has-success .form-control-feedback {
  color: #8CC152;
}

.has-warning .help-block, .has-warning .control-label, .has-warning .radio, .has-warning .checkbox, .has-warning .radio-inline, .has-warning .checkbox-inline {
  color: #F6BB42;
}
.has-warning .form-control {
  border-color: #F6BB42;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.has-warning .form-control:focus {
  border-color: #F6BB42;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.has-warning .input-group-addon {
  background-color: #F6BB42;
  border-color: #F6BB42;
}
.has-warning .form-control-feedback {
  color: #F6BB42;
}

.has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline {
  color: #DA4453;
}
.has-error .form-control {
  border-color: #DA4453;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.has-error .form-control:focus {
  border-color: #DA4453;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.has-error .input-group-addon {
  background-color: #DA4453;
  border-color: #DA4453;
}
.has-error .form-control-feedback {
  color: #DA4453;
}

/**
 * stepper
 * --------------------------------------------------
 */
.stepper .stepper-input {
  overflow: hidden;
  -moz-appearance: textfield;
}
.stepper .stepper-input::-webkit-inner-spin-button, .stepper .stepper-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.stepper .stepper-arrow {
  background-color: #3BAFDA;
  cursor: pointer;
  display: block;
  height: 50%;
  position: absolute;
  right: 15px;
  text-indent: -99999px;
  width: 20px;
}
.stepper .stepper-arrow:hover, .stepper .stepper-arrow:active {
  background-color: #4FC1E9;
}
.stepper .up {
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  border-top-right-radius: 3px;
  border: 1px solid #269ecb;
  top: 0;
}
.stepper .down {
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  border-bottom-right-radius: 3px;
  bottom: 0;
}
.stepper .up::before, .stepper .down::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
.stepper .up::before {
  top: 5px;
  left: 5px;
  border-bottom: 4px solid #FFF;
}
.stepper .down:before {
  bottom: 5px;
  left: 6px;
  border-top: 4px solid #FFF;
}
.stepper.disabled .stepper-arrow {
  background-color: #3BAFDA;
  opacity: 0.45;
  filter: alpha(opacity=45);
}

.selecter {
  display: block;
  position: relative;
  max-width: 100%;
  z-index: 1;
  outline: none;
  /* Open */
  /* 'Cover' Positioning */
  /* 'Bottom' Positioning */
  /* 'Bottom' + 'Cover' Positioning */
  /* Multiple Select */
  /* 'Disabled' State */
  /* Scroller Support */
}
.selecter .selecter-element {
  display: none;
  *left: -999999px;
  height: 100%;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 100%;
  z-index: 0;
}
.selecter .selecter-element, .selecter .selecter-element:focus {
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}
.selecter .selecter-selected {
  background-color: #FFF;
  border: 1px solid #AAB2BD;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  overflow: hidden;
  padding: 6px 10px;
  position: relative;
  text-overflow: clip;
  z-index: 2;
}
.selecter .selecter-selected:after {
  position: absolute;
  top: 14px;
  right: 10px;
  content: "";
  width: 0;
  height: 0;
  border-top: 4px solid #000;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
.selecter .selecter-options {
  border: 1px solid #AAB2BD;
  border-width: 0 1px 1px;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-color: #FFF;
  display: none;
  left: 0;
  max-height: 260px;
  overflow: auto;
  overflow-x: hidden;
  position: absolute;
  top: 100%;
  width: 100%;
  *width: auto;
  z-index: 50;
}
.selecter .selecter-group {
  border-bottom: 1px solid #E6E9ED;
  color: #AAB2BD;
  background-color: #F5F7FA;
  display: block;
  font-size: 11px;
  padding: 5px 10px 4px;
  text-transform: uppercase;
}
.selecter .selecter-item {
  background-color: #FFF;
  border-bottom: 1px solid #E6E9ED;
  cursor: pointer;
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 6px 10px;
  text-overflow: ellipsis;
  width: 100%;
}
.selecter .selecter-item.selected {
  color: #FFF;
  border-bottom-color: #4FC1E9;
  background-color: #3BAFDA;
}
.selecter .selecter-item.disabled {
  color: #AAB2BD;
  cursor: default;
}
.selecter .selecter-item:first-child {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.selecter .selecter-item:last-child {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  border-bottom: 0;
}
.selecter .selecter-item:hover {
  background-color: #E6E9ED;
}
.selecter .selecter-item.selected:hover {
  background-color: #3BAFDA;
}
.selecter .selecter-item.disabled:hover, .selecter:hover .selecter-selected, .selecter.disabled .selecter-item:hover {
  background-color: #FFF;
}
.selecter.open {
  outline: 0;
  z-index: 3;
}
.selecter.open .selecter-selected {
  border: 1px solid #3BAFDA;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  z-index: 51;
}
.selecter.open .selecter-selected, .selecter.focus .selecter-selected {
  background-color: #FFF;
}
.selecter.cover .selecter-options {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border-width: 1px;
  top: 0;
}
.selecter.cover .selecter-options .selecter-item.first {
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.selecter.cover.open .selecter-selected {
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  z-index: 49;
}
.selecter.bottom .selecter-options {
  border-width: 1px 1px 0;
  bottom: 100%;
  top: auto;
}
.selecter.bottom .selecter-item:last-child {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: none;
}
.selecter.bottom.open .selecter-selected {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
.selecter.bottom.open .selecter-options {
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.selecter.bottom.cover .selecter-options {
  bottom: 0;
  top: auto;
}
.selecter.bottom.cover.open .selecter-selected, .selecter.bottom.cover.open .selecter-options {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.selecter.multiple .selecter-options {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border-width: 1px;
  box-shadow: none;
  display: block;
  position: static;
  width: 100%;
}
.selecter.disabled .selecter-selected {
  background-color: #E6E9ED;
  border-color: #E6E9ED;
  color: #AAB2BD;
  cursor: default;
}
.selecter.disabled .selecter-options {
  background-color: #E6E9ED;
  border-color: #E6E9ED;
}
.selecter.disabled .selecter-group, .selecter.disabled .selecter-item {
  background-color: #E6E9ED;
  border-color: #E6E9ED;
  color: #AAB2BD;
  cursor: default;
}
.selecter.disabled .selecter-item.selected {
  background-color: #3BAFDA;
  opacity: 0.45;
  filter: alpha(opacity=45);
  color: #FFF;
}
.selecter .selecter-options.scroller {
  overflow: hidden;
}
.selecter .selecter-options.scroller .scroller-content {
  max-height: 260px;
  padding: 0;
}

/**
 * toggle
 * --------------------------------------------------
 */
.toggle {
  height: 32px;
}
.toggle input[type=checkbox], .toggle input[type=radio] {
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  text-indent: -100000px;
  opacity: 0;
  filter: alpha(opacity=0);
}
.toggle .handle {
  display: block;
  position: relative;
  top: -20px;
  left: 0;
  width: 50px;
  height: 32px;
  background-color: #FFF;
  -webkit-border-radius: 19px;
  -moz-border-radius: 19px;
  border-radius: 19px;
  -webkit-box-shadow: inset 0 0 0 1px #b8bfc8;
  -moz-box-shadow: inset 0 0 0 1px #b8bfc8;
  box-shadow: inset 0 0 0 1px #b8bfc8;
}
.toggle .handle:before, .toggle .handle:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  display: block;
  width: 30px;
  height: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  background-color: #FFF;
  -webkit-box-shadow: inset 0 0 0 1px #b8bfc8, 1px 1px 1px #c7ccd3;
  -moz-box-shadow: inset 0 0 0 1px #b8bfc8, 1px 1px 1px #c7ccd3;
  box-shadow: inset 0 0 0 1px #b8bfc8, 1px 1px 1px #c7ccd3;
}
.toggle input[type=checkbox]:disabled + .handle, .toggle input[type=radio]:disabled + .handle, .toggle input[type=checkbox]:disabled + .handle:before, .toggle input[type=radio]:disabled + .handle:before, .toggle input[type=checkbox]:disabled + .handle:after, .toggle input[type=radio]:disabled + .handle:after {
  opacity: 0.6;
  filter: alpha(opacity=60);
  background-color: #E6E9ED;
}
.toggle input[type=checkbox]:checked + .handle:before, .toggle input[type=radio]:checked + .handle:before {
  width: 50px;
  background-color: #A0D468;
}
.toggle input[type=checkbox]:checked + .handle:after, .toggle input[type=radio]:checked + .handle:after {
  left: 20px;
  -webkit-box-shadow: inset 0 0 0 1px #F5F7FA, 1px 1px 1px #c7ccd3;
  -moz-box-shadow: inset 0 0 0 1px #F5F7FA, 1px 1px 1px #c7ccd3;
  box-shadow: inset 0 0 0 1px #F5F7FA, 1px 1px 1px #c7ccd3;
}

/**
 * alert
 * --------------------------------------------------
 */
.alert h4 {
  margin-bottom: 10px;
  font-weight: bold;
}
.alert-dismissable .close {
  color: #000;
}
.alert-info {
  background-color: #7cd1ef;
  border: #4FC1E9;
}

.alert-warning {
  background-color: #ffdd87;
  border: #FFCE54;
}

.alert-danger {
  background-color: #f2838f;
  border: #ED5565;
}

.alert-success {
  background-color: #b9df90;
  border: #A0D468;
}

.alert .alert-link {
  cursor: pointer;
  text-decoration: underline;
  opacity: 0.65;
  filter: alpha(opacity=65);
}
.alert .alert-link:hover, .alert .alert-link:focus {
  opacity: 0.45;
  filter: alpha(opacity=45);
}

.alert .btn-link, .alert .btn-link:hover, .alert .btn-link:focus {
  color: #000;
  opacity: 0.65;
  filter: alpha(opacity=65);
}

.alert .btn-link:focus, .alert .btn-link:hover {
  text-decoration: none;
  opacity: 0.4;
  filter: alpha(opacity=40);
}

/**
 * tab
 * --------------------------------------------------
 */
.nav-tabs {
  border-bottom: none;
  background-color: #E6E9ED;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.nav-tabs > li {
  margin-bottom: 0;
  border-left: 1px solid #CCD1D9;
}
.nav-tabs > li:first-child {
  border-left: none;
}
.nav-tabs > li > a {
  margin-right: 0;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  color: #434A54;
}
.nav-tabs > li:first-child > a {
  -webkit-border-radius: 4px 0 0 0;
  -moz-border-radius: 4px 0 0 0;
  border-radius: 4px 0 0 0;
}
.nav-tabs > li > a:focus, .nav-tabs > li > a:hover {
  border: none;
  background-color: #F5F7FA;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
  border: none;
  background-color: #FFF !important;
}
.nav-tabs .dropdown-toggle, .nav-tabs .dropdown-toggle:hover, .nav-tabs .dropdown-toggle:focus {
  color: #434A54;
}
.nav-tabs li.dropdown.open .dropdown-toggle {
  color: #434A54;
  background-color: #F5F7FA;
}
.nav-tabs li.dropdown.active.open .dropdown-toggle {
  color: #434A54;
}
.nav-tabs li.dropdown.active.open .dropdown-toggle .caret, .nav-tabs li.dropdown.active .dropdown-toggle .caret {
  border-top-color: #434A54;
  border-bottom-color: #434A54;
}
.nav-tabs li.dropdown.open .caret, .nav-tabs li.dropdown.open.active .caret, .nav-tabs li.dropdown.open a:hover .caret, .nav-tabs li.dropdown.open a:focus .caret, .nav-tabs .dropdown-toggle .caret, .nav-tabs .dropdown-toggle:hover .caret, .nav-tabs .dropdown-toggle:focus .caret {
  border-top-color: #434A54;
  border-bottom-color: #434A54;
}
.nav-tabs.nav-justified > li > a {
  margin-bottom: 0;
  text-align: center;
}
.nav-tabs.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto;
}
.nav-tabs.nav-justified > li > a {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.nav-tabs.nav-justified > li:first-child > a {
  -webkit-border-radius: 4px 0 0 0;
  -moz-border-radius: 4px 0 0 0;
  border-radius: 4px 0 0 0;
}
.nav-tabs.nav-justified > li:last-child > a {
  -webkit-border-radius: 0 4px 0 0;
  -moz-border-radius: 0 4px 0 0;
  border-radius: 0 4px 0 0;
}
.nav-tabs.nav-justified > .active > a, .nav-tabs.nav-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:focus {
  border: none;
}
@media (min-width: 768px) {
  .nav-tabs.nav-justified > li > a {
    border-bottom: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .nav-tabs.nav-justified > .active > a, .nav-tabs.nav-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:focus {
    border-bottom: none;
  }
}
.tab-content {
  padding: 10px;
}

.tabs-below .nav-tabs {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
.tabs-below .nav-tabs > li:first-child > a {
  -webkit-border-radius: 0 0 0 4px;
  -moz-border-radius: 0 0 0 4px;
  border-radius: 0 0 0 4px;
}
.tabs-below .nav-tabs.nav-justified > li:last-child > a {
  -webkit-border-radius: 0 0 4px 0;
  -moz-border-radius: 0 0 4px 0;
  border-radius: 0 0 4px 0;
}

.tabs-left .nav-tabs > li,
.tabs-right .nav-tabs > li {
  float: none;
  border-left: none;
  border-top: 1px solid #CCD1D9;
}

.tabs-left .nav-tabs > li:first-child,
.tabs-right .nav-tabs > li:first-child {
  border-top: none;
}

.tabs-left .nav-tabs > li > a,
.tabs-right .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
}

.tabs-left .nav-tabs {
  float: left;
  margin-right: 19px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.tabs-left .nav-tabs > li:first-child > a {
  -webkit-border-radius: 4px 0 0 0;
  -moz-border-radius: 4px 0 0 0;
  border-radius: 4px 0 0 0;
}
.tabs-left .nav-tabs > li:last-child > a {
  -webkit-border-radius: 0 0 0 4px;
  -moz-border-radius: 0 0 0 4px;
  border-radius: 0 0 0 4px;
}

.tabs-right .nav-tabs {
  float: right;
  margin-left: 19px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.tabs-right .nav-tabs > li:first-child > a {
  -webkit-border-radius: 0 4px 0 0;
  -moz-border-radius: 0 4px 0 0;
  border-radius: 0 4px 0 0;
}
.tabs-right .nav-tabs > li:last-child > a {
  -webkit-border-radius: 0 0 4px 0;
  -moz-border-radius: 0 0 4px 0;
  border-radius: 0 0 4px 0;
}

/**
 * pill
 * --------------------------------------------------
 */
.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  color: #FFF;
  background-color: #3BAFDA;
}
.nav-pills > li > a {
  color: #3BAFDA;
}
.nav-pills > li > a:hover {
  color: #434A54;
  background-color: #E6E9ED;
}
.nav-pills > .active > a > .badge {
  color: #3BAFDA;
}
.nav-pills .open > a, .nav-pills .open > a:focus, .nav-pills .open > a:hover {
  color: #434A54;
  background-color: #E6E9ED;
}

/**
 * navbar
 * --------------------------------------------------
 */
.navbar-form {
  padding: 0 !important;
}

.navbar-default {
  background-color: #37BC9B;
  border-color: #37BC9B;
}
.navbar-default .navbar-brand, .navbar-default .navbar-link, .navbar-default .btn-link {
  color: #26816a;
}
.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus, .navbar-default .navbar-link:hover, .navbar-default .btn-link:hover, .navbar-default .btn-link:focus {
  color: #FFF;
  background-color: transparent;
}
.navbar-default .navbar-text, .navbar-default .navbar-nav > li > a {
  color: #26816a;
}
.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
  color: #FFF;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  color: #FFF;
  background-color: #48CFAD;
}
.navbar-default .btn-link[disabled]:hover, fieldset[disabled] .navbar-default .btn-link:hover, .navbar-default .btn-link[disabled]:focus, fieldset[disabled] .navbar-default .btn-link:focus, .navbar-default .navbar-nav > .disabled > a, .navbar-default .navbar-nav > .disabled > a:hover, .navbar-default .navbar-nav > .disabled > a:focus {
  color: #2e9c81;
  background-color: transparent;
}
.navbar-default .navbar-toggle {
  border-color: #26816a;
  background-color: #26816a;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
  border-color: #2b957a;
  background-color: #2b957a;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: #37BC9B;
}
.navbar-default .navbar-collapse, .navbar-default .navbar-form {
  border-color: #48CFAD;
}
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  color: #FFF;
  background-color: #37BC9B;
}
@media (max-width: 767px) {
  .navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
    background-color: #48CFAD;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .divider {
    background-color: #48CFAD;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #26816a;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus, .navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #FFF;
    background-color: #48CFAD;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .dropdown-header {
    color: #26816a;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #2b957a;
  }
}

.navbar-inverse {
  background-color: #333333;
  border-color: #333333;
}
.navbar-inverse .navbar-brand, .navbar-inverse .navbar-link, .navbar-inverse .btn-link {
  color: #8c8c8c;
}
.navbar-inverse .navbar-brand:hover, .navbar-inverse .navbar-brand:focus, .navbar-inverse .navbar-link:hover, .navbar-inverse .btn-link:hover, .navbar-inverse .btn-link:focus {
  color: #FFF;
  background-color: transparent;
}
.navbar-inverse .navbar-text, .navbar-inverse .navbar-nav > li > a {
  color: #8c8c8c;
}
.navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus {
  color: #FFF;
}
.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
  color: #FFF;
  background-color: #000;
}
.navbar-inverse .btn-link[disabled]:hover, fieldset[disabled] .navbar-inverse .btn-link:hover, .navbar-inverse .btn-link[disabled]:focus, fieldset[disabled] .navbar-inverse .btn-link:focus, .navbar-inverse .navbar-nav > .disabled > a, .navbar-inverse .navbar-nav > .disabled > a:hover, .navbar-inverse .navbar-nav > .disabled > a:focus {
  color: #666666;
  background-color: transparent;
}
.navbar-inverse .navbar-toggle {
  border-color: #000;
  background-color: #000;
}
.navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus {
  border-color: #1a1a1a;
  background-color: #1a1a1a;
}
.navbar-inverse .navbar-toggle .icon-bar {
  background-color: #8c8c8c;
}
.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
  border-color: #000;
}
.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
  color: #FFF;
  background-color: #000;
}
@media (max-width: 767px) {
  .navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus {
    background-color: #000;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > .divider {
    background-color: #000;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #8c8c8c;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #FFF;
    background-color: #000;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
    color: #bfbfbf;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #666666;
  }
}

/**
 * list
 * --------------------------------------------------
 */
.list-group {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.list-group-item {
  border-color: transparent;
  border-top-color: #E6E9ED;
}
.list-group-item:first-child {
  border-top: none;
}
.list-group-item-heading {
  color: #434A54;
}

a.list-group-item {
  color: #434A54;
}
a.list-group-item .list-group-item-heading {
  font-size: 16px;
  color: #434A54;
}
a.list-group-item:hover, a.list-group-item:focus {
  background-color: #E6E9ED;
}
a.list-group-item.active, a.list-group-item.active:hover, a.list-group-item.active:focus {
  background-color: #4FC1E9;
  border-color: #4FC1E9;
}
a.list-group-item.active .list-group-item-text, a.list-group-item.active:hover .list-group-item-text, a.list-group-item.active:focus .list-group-item-text {
  color: #FFF;
}

.list-group-item-primary {
  color: #22b1e3;
  border-color: #3BAFDA transparent transparent transparent;
  background-color: #4FC1E9;
}
.list-group-item-primary:first-child {
  border-color: transparent;
}
a.list-group-item-primary {
  color: #126d8d;
}
a.list-group-item-primary:hover, a.list-group-item-primary:focus {
  color: #FFF;
  background-color: #3BAFDA;
}
a.list-group-item-primary.active, a.list-group-item-primary:hover, a.list-group-item-primary:focus {
  background-color: #3BAFDA;
  border-color: #4FC1E9 transparent transparent transparent;
}

.list-group-item-success {
  color: #87c940;
  border-color: #8CC152 transparent transparent transparent;
  background-color: #A0D468;
}
.list-group-item-success:first-child {
  border-color: transparent;
}
a.list-group-item-success {
  color: #537f24;
}
a.list-group-item-success:hover, a.list-group-item-success:focus {
  color: #FFF;
  background-color: #8CC152;
}
a.list-group-item-success.active, a.list-group-item-success:hover, a.list-group-item-success:focus {
  background-color: #8CC152;
  border-color: #A0D468 transparent transparent transparent;
}

.list-group-item-warning {
  color: #ffbf21;
  border-color: #F6BB42 transparent transparent transparent;
  background-color: #FFCE54;
}
.list-group-item-warning:first-child {
  border-color: transparent;
}
a.list-group-item-warning {
  color: #876000;
}
a.list-group-item-warning:hover, a.list-group-item-warning:focus {
  color: #FFF;
  background-color: #F6BB42;
}
a.list-group-item-warning.active, a.list-group-item-warning:hover, a.list-group-item-warning:focus {
  background-color: #F6BB42;
  border-color: #FFCE54 transparent transparent transparent;
}

.list-group-item-info {
  color: #2fb593;
  border-color: #37BC9B transparent transparent transparent;
  background-color: #48CFAD;
}
.list-group-item-info:first-child {
  border-color: transparent;
}
a.list-group-item-info {
  color: #1a6451;
}
a.list-group-item-info:hover, a.list-group-item-info:focus {
  color: #FFF;
  background-color: #37BC9B;
}
a.list-group-item-info.active, a.list-group-item-info:hover, a.list-group-item-info:focus {
  background-color: #37BC9B;
  border-color: #48CFAD transparent transparent transparent;
}

.list-group-item-danger {
  color: #e8273b;
  border-color: #DA4453 transparent transparent transparent;
  background-color: #ED5565;
}
.list-group-item-danger:first-child {
  border-color: transparent;
}
a.list-group-item-danger {
  color: #99101f;
}
a.list-group-item-danger:hover, a.list-group-item-danger:focus {
  color: #FFF;
  background-color: #DA4453;
}
a.list-group-item-danger.active, a.list-group-item-danger:hover, a.list-group-item-danger:focus {
  background-color: #DA4453;
  border-color: #ED5565 transparent transparent transparent;
}

/**
 * modal
 * --------------------------------------------------
 */
.modal-content {
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #434A54;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.modal-header {
  border-bottom: none;
}
.modal-body {
  padding: 0 15px;
}
.modal-footer {
  border-top: none;
}

/**
 * well
 * --------------------------------------------------
 */
.well {
  padding: 10px;
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #434A54;
  background-color: #FFF;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.well blockquote {
  border-color: #CCD1D9;
}
.well-lg {
  padding: 20px;
}

.well-sm {
  padding: 5px;
}

/**
 * panel
 * --------------------------------------------------
 */
.panel {
  background-color: #FFF;
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.panel .list-group {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.panel .list-group-item:first-child {
  border-top: 1px solid #E6E9ED;
}
.panel-heading {
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.panel-title {
  font-size: 14px;
  color: #434A54;
  font-weight: normal;
}
.panel-footer {
  background-color: #E6E9ED;
  border-top-color: #E6E9ED;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
.panel-default {
  border-color: #E6E9ED;
}
.panel-default > .panel-heading {
  color: #434A54;
  background-color: #E6E9ED;
  border-color: #E6E9ED;
}

.panel-primary {
  border-color: #3BAFDA;
}
.panel-primary > .panel-heading {
  color: #FFF;
  background-color: #3BAFDA;
  border-color: #3BAFDA;
}

.panel-success {
  border-color: #8CC152;
}
.panel-success > .panel-heading {
  color: #FFF;
  background-color: #8CC152;
  border-color: #8CC152;
}

.panel-info {
  border-color: #37BC9B;
}
.panel-info > .panel-heading {
  color: #FFF;
  background-color: #37BC9B;
  border-color: #37BC9B;
}

.panel-warning {
  border-color: #F6BB42;
}
.panel-warning > .panel-heading {
  color: #FFF;
  background-color: #F6BB42;
  border-color: #F6BB42;
}

.panel-danger {
  border-color: #DA4453;
}
.panel-danger > .panel-heading {
  color: #FFF;
  background-color: #DA4453;
  border-color: #DA4453;
}

.panel-primary > .panel-heading > .panel-title, .panel-success > .panel-heading > .panel-title, .panel-info > .panel-heading > .panel-title, .panel-warning > .panel-heading > .panel-title, .panel-danger > .panel-heading > .panel-title {
  color: #FFF;
}

.panel > .list-group:first-child .list-group-item:first-child, .panel > .table:first-child, .panel > .table-responsive:first-child > .table:first-child {
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.panel > .list-group:last-child .list-group-item:last-child {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
.panel > .table:first-child > thead:first-child > tr:first-child td:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child td:first-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child, .panel > .table:first-child > thead:first-child > tr:first-child th:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child th:first-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
  -webkit-border-radius: 4px 0 0 0;
  -moz-border-radius: 4px 0 0 0;
  border-radius: 4px 0 0 0;
}
.panel > .table:first-child > thead:first-child > tr:first-child td:last-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child, .panel > .table:first-child > tbody:first-child > tr:first-child td:last-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child, .panel > .table:first-child > thead:first-child > tr:first-child th:last-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child, .panel > .table:first-child > tbody:first-child > tr:first-child th:last-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
  -webkit-border-radius: 0 4px 0 0;
  -moz-border-radius: 0 4px 0 0;
  border-radius: 0 4px 0 0;
}
.panel > .table:last-child, .panel > .table-responsive:last-child > .table:last-child {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child, .panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child, .panel > .table:last-child > tbody:last-child > tr:last-child th:first-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child, .panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
  -webkit-border-radius: 0 0 0 4px;
  -moz-border-radius: 0 0 0 4px;
  border-radius: 0 0 0 4px;
}
.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child, .panel > .table:last-child > tbody:last-child > tr:last-child th:last-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
  -webkit-border-radius: 0 0 4px 0;
  -moz-border-radius: 0 0 4px 0;
  border-radius: 0 0 4px 0;
}
.panel > .panel-body + .table, .panel > .panel-body + .table-responsive {
  border-top-color: #E6E9ED;
}

/**
 * accordion
 * --------------------------------------------------
 */
.panel-group .panel {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.panel-group .panel + .panel {
  margin-top: 0;
}
.panel-group .panel-heading {
  padding: 0;
  border-bottom-color: transparent;
}
.panel-group .panel-heading + .panel-collapse .panel-body {
  padding: 15px 0;
  border-top-color: transparent;
}
.panel-group .panel-title a {
  padding: 10px 0;
  display: block;
}

.panel-group-lists .panel {
  border-bottom: 1px solid #E6E9ED;
  background-color: #FFF;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.panel-group-lists .panel:first-child {
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.panel-group-lists .panel:last-child {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  border-bottom: none;
}
.panel-group-lists .panel-heading + .panel-collapse .panel-body {
  padding: 15px;
  border-top-color: #E6E9ED;
}
.panel-group-lists .panel-title a {
  padding: 10px 15px;
  color: #434A54;
}
.panel-group-lists .panel-title a:hover, .panel-group-lists .panel-title a:focus, .panel-group-lists .panel-title a:active {
  color: #AAB2BD;
}

/**
 * dropdown-menu
 * --------------------------------------------------
 */
.dropdown-menu {
  border: none;
  background-color: #434A54;
}
.dropdown-menu .dropdown-header {
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 700;
  color: #AAB2BD;
}
.dropdown-menu li a {
  padding: 5px 20px;
  color: #FFF;
}
.dropdown-menu li a:hover, .dropdown-menu li a:focus, .dropdown-menu .active a, .dropdown-menu .active a:hover, .dropdown-menu .active a:focus {
  color: #FFF;
  background-color: #656D78;
  outline: none;
}
.dropdown-menu .disabled a, .dropdown-menu .disabled a:hover, .dropdown-menu .disabled a:focus {
  color: #656D78;
  cursor: default;
}
.dropdown-menu .divider {
  background-color: #656D78;
  border-bottom: none;
}
.dropup .dropdown-menu {
  margin-bottom: 0;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}

/**
 * dropdown-submenu
 * --------------------------------------------------
 */
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 4px 4px 4px;
  -moz-border-radius: 0 4px 4px 4px;
  border-radius: 0 4px 4px 4px;
}
.dropdown-submenu:hover .dropdown-menu {
  display: block;
}
.dropup .dropdown-submenu .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-top: 0;
  margin-bottom: -2px;
  -webkit-border-radius: 4px 4px 4px 0;
  -moz-border-radius: 4px 4px 4px 0;
  border-radius: 4px 4px 4px 0;
}
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #FFF;
  margin-top: 5px;
  margin-right: -10px;
}
.dropdown-default .dropdown-submenu > a:after {
  border-left-color: #434A54;
}

.dropdown-submenu:hover a:after {
  border-left-color: #FFF;
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 4px 0 4px 4px;
  -moz-border-radius: 4px 0 4px 4px;
  border-radius: 4px 0 4px 4px;
}

/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.navbar-default {
  background: white;
  border-color: white;
  border-bottom: 1px solid #AAB2BD;
  box-shadow: none;
  border-radius: 0;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  background: #7da836;
}
.nav-pills > li > a {
  border-radius: 100px;
  color: #434A54;
  padding-top: 8px;
  padding-bottom: 8px;
}

.nav-borders > li {
  background: none;
}
.nav-borders > li > a {
  border-bottom: 4px solid transparent;
  border-radius: 0;
  background: none;
}
.nav-borders > li > a:hover, .nav-borders > li > a:focus {
  color: #383e46;
  background: none;
}
.nav-borders > li.active > a {
  background: none;
  border-bottom-color: #556e32;
  color: #434A54;
}
.nav-borders > li.active > a:hover, .nav-borders > li.active > a:focus {
  color: #383e46;
  background: none;
}

.navbar.navbar-default .navbar-brand {
  color: #556e32;
}
.navbar.navbar-default .navbar-brand:hover {
  color: #556e32;
}
.navbar.navbar-default .navbar-nav > li > a {
  color: #404041;
}
.navbar.navbar-default .navbar-nav > li > a:hover {
  color: #404041;
}
.navbar.navbar-default .navbar-nav > .open > a {
  background: transparent;
  color: #404041;
}

h1 {
  font-family: "PT Sans Narrow", sans-serif;
}

.muted {
  color: #6d6e70;
}

a {
  color: #44a6cf;
}
a:hover {
  color: #44a6cf;
}

p.code,
textarea.code {
  background: #E6E9ED;
  color: #434A54;
  border-radius: 4px;
  font-family: monospace;
  margin: 0;
  padding: 15px;
  overflow: auto;
  word-wrap: break-word;
  width: 100%;
  resize: none;
  border: none;
}

.formGroup--double {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 15px;
}
.formGroup--double > .form-group {
  margin-bottom: 0;
}
.formGroup--double > * {
  flex: 0 0 50%;
  padding: 0 5px;
  margin-left: -5px;
  margin-right: -5px;
}
.formGroup--double > * + * {
  margin-left: 10px;
}

textarea {
  resize: vertical;
}

.Select-control {
  height: 34px;
}

.Select-input {
  height: 32px;
}

.Select-menu-outer {
  z-index: 120;
}

.datetime-wrap {
  position: relative;
}
.datetime-wrap span.glyphicon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
  color: #6d6e70;
}

.datetime-input {
  position: relative;
  padding-left: 30px;
}

.btn {
  border-color: transparent;
  transition: all 0.15s;
  font-family: "Karla", sans-serif;
}
.btn.shadow {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.btn-link.btn-link-danger {
  color: #c55337;
}

.btn-group .btn-default:focus,
.btn-default:focus {
  background: white;
  color: #434A54;
}

.btn-info {
  background: #37BC9B;
  border-color: #37BC9B;
}
.btn-info:hover, .btn-info:active:hover, .btn-info:active, .btn-info:focus {
  background: #31a88b;
  border-color: #31a88b;
}

/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.panel {
  margin-bottom: 15px;
}

.panel-group .panel-heading {
  padding: 5px 10px;
}
.panel-group .panel + .panel .panel-heading {
  border-radius: 0;
}

.panel-default > .panel-heading {
  background: white;
  font-size: 18px;
  font-family: "PT Sans Narrow", sans-serif;
}

.panel .well {
  background: #F5F7FA;
  border: 1px solid #E6E9ED;
  box-shadow: none;
}

.panel .panelHeader {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.panel .panelHeader h2 {
  font-size: 18px;
  margin: 0;
}
.panel .panelHeader .panelHeader-actions {
  margin-left: auto;
}

.Select--multi .Select-value {
  color: white;
  background-color: #5D9CEC;
  border-color: #4A89DC;
}
.Select--multi .Select-value-icon {
  border-color: #4A89DC;
}
.Select--multi .Select-value-icon:hover {
  color: #e6e6e6;
}

.Select-control {
  border-color: #AAB2BD;
}

.pagination > .disabled > span, .pagination > .disabled > span:hover, .pagination > .disabled > span:focus, .pagination > .disabled > a, .pagination > .disabled > a:hover, .pagination > .disabled > a:focus {
  color: #6d6e70;
}

.progress {
  height: 15px;
}

.progress-bar {
  background-color: #7da836;
}

a {
  cursor: pointer;
}

body {
  background: #F8f4ec;
  color: #434A54;
}
body > * {
  font-family: "Karla", "Helvetica Neue", helvetica, arial, sans-serif;
}

.dropdown {
  font-family: "Karla", "Helvetica Neue", helvetica, arial, sans-serif;
}

.label {
  font-family: "PT Sans Narrow", sans-serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.label-danger {
  background-color: #c55337;
}

.label-warning {
  background-color: #FFCE54;
}

.label-info {
  background-color: #44a6cf;
}

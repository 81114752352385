/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.contentBundle-header__titleActions {
  margin-left: auto;
}
.contentBundle-header__titleActions > * {
  margin-left: 8px;
}

.contentBundle-wrap .pageLayout-header {
  background: #656D78;
  padding-bottom: 15px;
  padding-top: 10px;
}
.contentBundle-wrap .error-message {
  color: #c55337;
}

.pageLayout-content .contentBundle-wrap .pageLayout-header {
  background: #656D78;
  padding-top: 10px;
}

.contentBundle-header__title {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
}
.contentBundle-header__title .glyphicon-chevron-left {
  margin-right: 3px;
}

.contentBundle-header__titleText .btn.btn-link {
  color: rgba(255, 255, 255, 0.4);
  margin-left: -15px;
}
.contentBundle-header__titleText h1 {
  color: white;
}

.contentBundleInfo-actions {
  margin-bottom: 20px;
  display: flex;
  flex-flow: row nowrap;
}
.contentBundleInfo-actions .btn {
  margin-left: auto;
}

.contentBundleInfo-wrap .panel {
  position: relative;
}

.contentBundleSection-hidden {
  display: none;
}

.contentBundleSection-image {
  display: block;
  position: relative;
  height: 150px;
  width: 250px;
  border: 1px solid #AAB2BD;
  border-radius: 3px;
  overflow: hidden;
  background: #F8f4ec;
  margin-bottom: 15px;
}
.contentBundleSection-image .contentBundleImage-imageButton {
  bottom: 10px;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  width: 100px;
  z-index: 5;
}
.contentBundleSection-image .contentBundleImage-imageButton:hover {
  background: white;
  color: #434A54;
}
.contentBundleSection-image .contentBundleSection-bannerImage {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.contentBundleInfo-content {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}
.contentBundleInfo-content > * {
  flex: 1 1 auto;
}

.contentBundleInfo-associations {
  margin-left: 15px;
  flex: 0 0 40%;
}

.contentBundleInfo-actions {
  margin-bottom: 20px;
  display: flex;
  flex-flow: row nowrap;
}
.contentBundleInfo-actions .btn {
  margin-left: auto;
}

.contentBundleInfo-wrap .panel {
  position: relative;
}

.contentBundleSection-hidden {
  display: none;
}

.contentBundleSection-image {
  display: block;
  position: relative;
  height: 150px;
  width: 250px;
  border: 1px solid #AAB2BD;
  border-radius: 3px;
  overflow: hidden;
  background: #F8f4ec;
  margin-bottom: 15px;
}
.contentBundleSection-image .contentBundleImage-imageButton {
  bottom: 10px;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  width: 100px;
  z-index: 5;
}
.contentBundleSection-image .contentBundleImage-imageButton:hover {
  background: white;
  color: #434A54;
}
.contentBundleSection-image .contentBundleSection-bannerImage {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.contentBundleInfo-content {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}
.contentBundleInfo-content > * {
  flex: 1 1 auto;
}

.contentBundleInfo-associations {
  margin-left: 15px;
  flex: 0 0 40%;
}

/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.featureInfo-actions {
  display: flex;
  flex-flow: row nowrap;
}
.featureInfo-actions .btn {
  margin-left: auto;
}

.featureInfo-wrap .panel {
  position: relative;
}
.featureInfo-wrap .panel-wrap--double {
  display: flex;
  flex-flow: row nowrap;
}
.featureInfo-wrap .panel-wrap--double .panel + .panel {
  margin-left: 15px;
  width: 40%;
  flex: 0 0 auto;
}

.featureInfo-logo .btn {
  margin-left: 15px;
}

.featureInfo--basic {
  flex: 1 1 auto;
}

.error-message {
  color: #c55337;
}

.featureSection--additionalInfo {
  margin-top: -15px;
}

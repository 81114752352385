/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.user-header__title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.user-header__actions {
  margin-left: auto;
}

.userForm__actions {
  margin-bottom: 20px;
}

.userInfo-content {
  position: relative;
}

.userInfo-image {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.userInfo-image .control-label {
  margin-right: 10px;
  display: inline-block;
}
.userInfo-image .userInfo-imagePreview {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100px;
  width: 100px;
  border: 1px solid #AAB2BD;
  margin-right: 10px;
  border-radius: 3px;
}

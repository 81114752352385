@import "src/config.scss";

.contentBundlesList-wrap {
  padding: 15px;
}

.contentBundlesList-content {
  display: flex;
  flex-flow: row nowrap;
}

.contentBundlesList-filters {
  flex: 0 0 auto;
  width: 200px;
}

.contentBundlesList-table {
  flex: 1 1 auto;
  td {
    vertical-align: middle !important;
  }
}

.contentBundlesTable .panel-body {
  padding: 0;
}

.contentBundlesActionBar-wrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin-bottom: 10px;
}

.contentBundlesActionBar-actions {
  margin-left: auto;
}

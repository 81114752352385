.outingsList-wrap {
  padding: 15px;
}

.outingsList-content {
  display: flex;
  flex-flow: row nowrap;
}

.outingsList-table {
  flex: 1 1 auto;
}
.outingsList-table .table > thead > tr > th {
  vertical-align: middle;
  white-space: nowrap;
}
.outingsList-table td {
  vertical-align: middle !important;
}

.outingsList-loader {
  background: white;
  border-radius: 0 0 5px 5px;
  padding: 30px;
}

.outingsList-pagination {
  text-align: center;
}

.react-toggle {
  vertical-align: middle;
}

.react-toggle--checked.season-toggle .react-toggle-track {
  background-color: #86a648;
  width: 36px;
  height: 18px;
}
.react-toggle--checked.season-toggle .react-toggle-thumb {
  border-color: #86a648;
  left: 19px;
  width: 16px;
  height: 16px;
}
.react-toggle--checked.season-toggle .react-toggle--checked:hover:not(.react-toggle--disabled) {
  background-color: #86a648;
}

.react-toggle--checked:hover:not(.react-toggle--disabled).season-toggle .react-toggle-track {
  background-color: #86a648;
}

.react-toggle.season-toggle .react-toggle-track {
  width: 36px;
  height: 18px;
}
.react-toggle.season-toggle .react-toggle-thumb {
  width: 16px;
  height: 16px;
}
.react-toggle.season-toggle .react-toggle:hover:not(.react-toggle--disabled) {
  background-color: #86a648;
}

.featureInfo-toggleSpan {
  margin-left: 9px;
  margin-right: 12px;
  font-weight: 400;
}

/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.challenge-header__titleActions {
  margin-left: auto;
}
.challenge-header__titleActions > * {
  margin-left: 8px;
}

.challenge-wrap .pageLayout-header {
  background: #656D78;
  padding-bottom: 15px;
  padding-top: 10px;
}
.challenge-wrap .pageLayout-content {
  padding: 15px 0;
}
.challenge-wrap .pageLayout-content .col-xs-6:last-child {
  padding-left: 0;
}
.challenge-wrap .pageLayout-content .panel {
  margin-bottom: 0;
}
.challenge-wrap .error-message {
  color: #c55337;
}

.challenge-header__title {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
}
.challenge-header__title .glyphicon-chevron-left {
  margin-right: 3px;
}

.challenge-header__titleText .btn.btn-link {
  color: rgba(255, 255, 255, 0.4);
  margin-left: -15px;
}
.challenge-header__titleText h1 {
  color: white;
}

/* Form and Tasks */
.challenge-wrap .challengeSection-image,
.challengeTaskModal-form .challengeSection-image {
  display: block;
  position: relative;
  height: 150px;
  width: 150px;
  border: 1px solid #AAB2BD;
  border-radius: 3px;
  overflow: hidden;
  background: #F8f4ec;
  margin-bottom: 15px;
}
.challenge-wrap .challengeSection-image .challengeImage-imageButton,
.challengeTaskModal-form .challengeSection-image .challengeImage-imageButton {
  bottom: 10px;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  width: 100px;
  z-index: 5;
}
.challenge-wrap .challengeSection-image .challengeImage-imageButton:hover,
.challengeTaskModal-form .challengeSection-image .challengeImage-imageButton:hover {
  background: white;
  color: #434A54;
}
.challenge-wrap .challengeSection-image .challengeSection-badgeImage,
.challengeTaskModal-form .challengeSection-image .challengeSection-badgeImage {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/* Form */
.challengeForm-dateRow {
  display: flex;
  flex-flow: row nowrap;
}
.challengeForm-dateRow > * {
  flex: 0 0 50%;
}
.challengeForm-dateRow > *:first-child {
  padding-right: 10px;
}

.challengeSection-image .challengeImage-imageButton {
  visibility: hidden;
}

.challengeSection-image:hover .challengeImage-imageButton {
  visibility: visible;
}

/* Tasks */
.challengeTasks-wrap .panel-heading {
  position: relative;
}
.challengeTasks-wrap .panel-body {
  padding: 0;
}
.challengeTasks-wrap .panel-body .challengeTasks-form {
  border-bottom: solid 1px #E6E9ED;
  padding: 15px;
}
.challengeTasks-wrap .panel-body .challengeTasks-form .form-group {
  margin-bottom: 0;
}
.challengeTasks-wrap .panel-body .challengeTasks-form .help-block {
  margin-bottom: 0;
}
.challengeTasks-wrap .panel-body .empty-text {
  padding: 15px;
}

.challengeTasks-table .table {
  margin-bottom: 0;
  max-width: 100%;
  width: 100%;
}
.challengeTasks-table .table img.challengeTasks-table-badgeImage {
  height: 26px;
  width: 26px;
}
.challengeTasks-table .table th:first-child {
  padding-left: 15px;
  text-align: left;
}
.challengeTasks-table .table th {
  text-align: center;
}
.challengeTasks-table .table tr td:first-child {
  padding: 15px;
  text-align: left;
}
.challengeTasks-table .table tr td {
  padding-top: 15px;
  text-align: center;
}
.challengeTasks-table .table tr td .subtitle {
  font-style: italic;
}
.challengeTasks-table .table tr td.challengeTasks-table-badgeImage {
  padding-top: 10px;
}
.challengeTasks-table .table tr td:last-child {
  text-align: left;
  width: 1%;
  white-space: nowrap;
}

.challengeTaskModal-dialog .modal-body {
  padding: 15px 0 0 0;
}
.challengeTaskModal-dialog .modal-footer {
  border-top: 1px solid #E6E9ED;
}
.challengeTaskModal-dialog .redactor-toolbar {
  z-index: 0;
}

.challengeTaskModal-form {
  padding: 0 15px;
}

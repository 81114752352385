/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.documentsActionBar-wrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin-bottom: 10px;
}

.documentsActionBar-actionsRight {
  margin-left: auto;
}
.documentsActionBar-actionsRight > * {
  margin-left: 8px;
}

.documentsActionBar-actions {
  margin-left: auto;
}

/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.postModal-dialog .modal-body {
  padding: 0;
}
.postModal-dialog .modal-footer {
  border-top: 1px solid #E6E9ED;
}

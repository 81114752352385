.challenges .challenges-titleBar {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
}
.challenges .challenges-titleBar .challenges-actions {
  margin-left: auto;
  margin-right: 15px;
  margin-top: 15px;
}
.challenges .challenges-titleBar .challenges-actions .btn-primary:first-child {
  margin-right: 5px;
}
.challenges .challenges-list .pageLayout-header {
  padding-bottom: 15px;
}
.challenges .challenges-list .pageLayout-content {
  padding: 15px;
}
.challenges .challenges-list .table > tbody > tr > td {
  vertical-align: middle;
}
.challenges .challenges-list .challenge--actions {
  text-align: right;
}
.challenges .panel-body h3 {
  padding: 50px 30px;
  text-align: center;
}

/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.pageLayout-header {
  background: white;
  border-bottom: 1px solid #AAB2BD;
  padding: 15px 15px 0 15px;
}
.pageLayout-header h1 {
  margin: 0;
  font-size: 26px;
  font-weight: 300;
}

.pageLayout-content {
  padding: 15px;
}

.user-header {
  padding-bottom: 15px;
}

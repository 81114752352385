@import "src/config.scss";

.organizationExports-titleBar {
  margin-bottom: 15px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  .organizationExports-actions {
    margin-left: auto;
  }
}

.import-wrap {
  .pageLayout-header {
    background: $darkgray-light;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .panel-body > :last-child { margin-bottom: 0; }
}

.import-header__title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .glyphicon-chevron-left {
    margin-right: 3px;
  }
}

.import-header__titleText {
  .btn.btn-link {
    color: rgba(white, 0.4);
    margin-left: -15px;
  }

  h1 {
    color: white;
  }
}

.organizationExports-content {
  .empty-message {
    h3 {
      padding: 50px 30px;
      text-align: center;
    }
  }
  .table td {
    vertical-align: middle;
  }
  .table td.centered,
  .table th.centered {
    text-align: center;
  }
  .table td.nowrap {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .table td.actions {
    float: right;
  }
}

.organization-wrap {
  .uploader-close.btn {
    color: $text-light;
    font-size: 16px;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .uploader-container-inner {
    text-align: center;

    .btn-default {
      margin-bottom: 20px;
    }
  }
}

/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.partnerPointOfInterestSet-list {
  margin-bottom: 10px;
}
.partnerPointOfInterestSet-list > :first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.partnerPointOfInterestSet-list > :last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.partnerPointOfInterestSet-listItem {
  border: 1px solid #AAB2BD;
  padding: 6px 10px;
  display: flex;
  flex-flow: row nowrap;
}
.partnerPointOfInterestSet-listItem + .partnerPointOfInterestSet-listItem {
  border-top: none;
}

.partnerPointOfInterestSet-listItem__title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
  margin: 0;
  flex: 1 1 auto;
}

.partnerPointOfInterestSet-listItem__actions {
  margin-left: auto;
  flex: 0 0 auto;
}
.partnerPointOfInterestSet-listItem__actions .btn {
  color: #434A54;
  opacity: 0.7;
}

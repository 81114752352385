/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.posts-header {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  position: relative;
}

.posts-filters {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row wrap;
  align-items: center;
}
.posts-filters > * {
  flex: 0 0 auto;
  margin-right: 8px;
}
.posts-filters .select-wrap {
  min-width: 180px;
}
.posts-filters .glyphicon {
  color: #6d6e70;
  margin-right: 20px;
}

.posts-actions {
  margin-left: auto;
}

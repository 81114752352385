/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.mediaList-mediaItem {
  background-color: rgba(0, 0, 0, 0.2);
  background-size: cover;
  background-position: center center;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 2px;
  cursor: pointer;
  border: 3px solid white;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}
.mediaList-mediaItem:hover .mediaList-mediaItem__buttons {
  opacity: 1;
}
.mediaList-mediaItem.mediaList-listItem__upload {
  display: flex;
}
.mediaList-mediaItem.mediaItem--selected {
  box-shadow: 0 0 0 3px rgba(125, 168, 54, 0.85);
}
.mediaList-mediaItem .centeredLoader, .mediaList-mediaItem .centeredLoader--overlay, .mediaList-mediaItem .centeredLoader--overlay--noOpacity {
  z-index: inherit;
}

.mediaList-mediaItem__title {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  font-size: 12px;
  line-height: 1em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  padding: 5px 5px 3px;
}

.mediaList-mediaItem__select {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
}
.mediaList-mediaItem__select .checkbox {
  margin: 0;
}
.mediaList-mediaItem__select .checkbox input[type=checkbox] {
  position: absolute;
  top: 5px;
  left: 5px;
  margin: 0;
}
.mediaList-mediaItem__select .checkbox,
.mediaList-mediaItem__select label {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.mediaList-mediaItem__captionWarning {
  position: absolute;
  top: 4px;
  right: 6px;
  color: #FFCE54;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.mediaList-mediaItem__loadingText,
.mediaList-mediaItem__thumbnail,
.mediaList-mediaItem__buttons {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  text-align: center;
}

.mediaList-mediaItem__thumbnail span.img {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}

.mediaList-mediaItem__buttons {
  transition: opacity 0.25s 0.1s;
  opacity: 0;
  background: rgba(255, 255, 255, 0.3);
}
.mediaList-mediaItem__buttons .btn {
  color: white;
  font-size: 20px;
  opacity: 0.8;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);
}
.mediaList-mediaItem__buttons .btn:hover {
  color: white;
}

.mediaList-listItem.mediaList-listItem__upload {
  padding: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: #ccc;
  border: 5px solid #F8f4ec;
  border-radius: 2px;
}
.mediaList-listItem.mediaList-listItem__upload .progress {
  margin: 0;
  flex: 0 0 auto;
  width: 100%;
}

@import "src/config.scss";

.postItem {
  background: white;
  border: 1px solid $border-light;
  border-radius: 3px;
  max-width: 100%;
}

/* Banner */
.postItem-banner {
  background-color: $lightgray-dark;
  font-weight: bold;
  padding: 8px 16px;

  p {
    margin: 0;
  } 
}

.postItem-banner-admin {
  background-color: $sky-dark;
  color: white;
}

.postItem-banner-alert {
  background-color: $coral-light;
  color: white;
}

.postItem-banner-nonPost {
  background-color: $lightgray-light;
}

/* Header */
.postItem-header {
  align-items: center;
  border-bottom: 1px solid $border-light;
  display: flex;
  flex-flow: row nowrap;
  padding: 16px;
}

.postItem-header__avatar {
  border-radius: 100px;
  height: 40px;
  width: 40px;
  border: 1px solid $border-light;
  overflow: hidden;
  margin-right: 8px;
  background-size: cover;
  background-position: center center;
  flex: 0 0 auto;
}

.postItem-header__attribution {
  h3 {
    font-size: 16px;
    margin: 0 0 3px;
    font-weight: 600;
  }
  p {
    font-size: 13px;
    color: $text-light;
    margin: 0;
  }
}

.postItem-header__actions {
  margin-left: auto;

  .btn,
  .btn:hover { color: $text-light; }
}

/* Content */
.postItem-content {
  border-bottom: 1px solid $border-light;
  img {
    max-width: 100%;
    max-height: 400px;
  }
}

.postItem-photo {
  background-color: $darkgray-dark;
  text-align: center;
}

.postItem-textContent {
  padding: 16px;
  > :last-child { margin-bottom: 0; }
}

.postItem-reviewRating {
  margin: 16px;
}

.postItem-reviewRating-noBottomMargin {
  margin-bottom: 0;
}

.postItem-reportData {
  padding: 16px 16px 0 16px;
}

/* Actions */
.postItem-actions {
  display: flex;
  padding: 0 16px;

  p {
    margin: 8px 0 0 0;
  }

  .btn {
    color: $text-medium;
    margin-left: auto;

    &:hover { color: $primary; }
    &:active, &:focus {
      color: $text-medium;
      text-decoration: none;
    }
  }
  .like-button-active {
    color: $primary;
    font-weight: 600;
    &:active, &:focus {
      color: $primary;
    }
  }
}

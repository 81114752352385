/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.socialPosts {
  flex: 1 1 100%;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  margin-bottom: -15px;
  overflow-x: hidden;
}
.socialPosts .pageLayout-header {
  background: #F5F7FA;
  padding-bottom: 15px;
}
.socialPosts .postItems {
  max-width: 600px;
}

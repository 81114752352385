.documentsTable-tableRow > td:first-child {
  width: 100%;
}
.documentsTable-tableRow .form-group,
.documentsTable-tableRow .checkbox {
  margin: 0;
}

.documentsTable-progress p {
  margin: 0 0 5px;
}
.documentsTable-progress .progress {
  margin: 0;
}

.heroItemsList-wrap {
  padding: 15px;
}

.heroItemsList-content {
  display: flex;
  flex-flow: row nowrap;
}

.heroItemsList-table {
  flex: 1 1 auto;
}
.heroItemsList-table td {
  vertical-align: middle !important;
}

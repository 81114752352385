/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.campaign-wrap .pageLayout-header {
  background: #656D78;
  padding-top: 10px;
  padding-bottom: 10px;
}
.campaign-wrap .panel-body > :last-child {
  margin-bottom: 0;
}
.campaign-wrap .pageLayout-content {
  padding: 0 !important;
}

.campaign-header__title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.campaign-header__title .glyphicon-chevron-left {
  margin-right: 3px;
}

.campaign-header__titleText .btn.btn-link {
  color: rgba(255, 255, 255, 0.4);
  margin-left: -15px;
}
.campaign-header__titleText h1 {
  color: white;
}

.campaign-header__titleActions {
  margin-top: 10px;
  margin-left: auto;
}
.campaign-header__titleActions .btn {
  margin-left: 8px;
}

.campaign-infoPanel .control-label {
  display: block;
  margin: 0 0 5px;
}

.campaign-form .error-message {
  color: #c55337;
}
.campaign-form .panel-body {
  border-top: 1px solid #E6E9ED;
  background: #F5F7FA;
  margin: 0 -15px -15px;
  border-radius: 0 0 4px 4px;
}
.campaign-form .form-group.has-error .applicationSelect__control,
.campaign-form .form-group.has-error .communityIdSelect__control,
.campaign-form .form-group.has-error .entityTypeSelect__control {
  border-color: #c55337;
}
.campaign-form .form-group.has-error .help-block {
  color: #737373 !important;
}

/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.featuresActionBar-wrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}
.featuresActionBar-wrap .form-group {
  margin: 0;
}

.featuresActionBar-tabs .nav.nav-tabs {
  background: none;
  margin-left: -1px;
}
.featuresActionBar-tabs .nav.nav-tabs > li {
  background: #DEDAD1;
  border-radius: 5px 5px 0 0;
  border: 1px solid transparent;
  border-bottom: none;
  margin-right: 5px;
}
.featuresActionBar-tabs .nav.nav-tabs > li > a {
  border-radius: 5px 5px 0 0;
  transition: all 0.15s;
}
.featuresActionBar-tabs .nav.nav-tabs > li > a:hover {
  border-color: transparent;
  background: #E6E1D8;
}

.featuresActionBar-actions,
.featuresChildrenActionBar-actions {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 10px;
  margin-left: auto;
}
.featuresActionBar-actions .btn,
.featuresChildrenActionBar-actions .btn {
  margin-left: 8px;
}
.featuresActionBar-actions .associationSet,
.featuresChildrenActionBar-actions .associationSet {
  width: 250px;
}

.featuresChildrenActionBar-actions {
  margin-bottom: 7px !important;
}

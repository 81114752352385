/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.associationSet-list {
  margin-bottom: 10px;
}
.associationSet-list > :first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.associationSet-list > :last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.associationSet-listItem {
  border: 1px solid #AAB2BD;
  display: flex;
  flex-flow: row nowrap;
  padding: 6px 10px;
}
.associationSet-listItem + .associationSet-listItem {
  border-top: none;
}

.associationSet-listItem-content {
  float: left;
  width: calc(100% - 22px);
}
.associationSet-listItem-content p {
  margin: 0;
  overflow: hidden;
  padding-right: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.associationSet-listItem-content-sub {
  color: #6c6d70;
  font-style: italic;
}

.associationSet-listItem__actions {
  float: right;
  width: 22px;
}

.associationSet-listItem__actions-wrapper {
  height: 100%;
  position: relative;
}
.associationSet-listItem__actions-wrapper .btn {
  bottom: 0;
  color: #434A54;
  margin: auto;
  opacity: 0.7;
  position: absolute;
  right: 0;
  top: 0;
}

.featureSelectOption,
.entitySelectOption {
  cursor: pointer;
  padding: 10px 12px;
}
.featureSelectOption.isFocused,
.entitySelectOption.isFocused {
  background-color: #DEEAFF;
}

.form-group.has-error .entitySelect__control {
  border-color: #c55337;
}

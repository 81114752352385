/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.errorView {
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  background: #F8f4ec;
}
.errorView .well {
  max-width: 600px;
  margin: 100px auto 0;
  padding: 50px;
}

.errorView-container {
  text-align: center;
}

.mediaActionBar-wrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.mediaActionBar-wrap .form-group {
  margin: 0;
}

.mediaActionBar-deselectButton {
  padding-left: 0;
}

.mediaActionBar-actionsRight {
  margin-left: auto;
}
.mediaActionBar-actionsRight > * {
  margin-left: 8px;
}

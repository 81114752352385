/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.outingsTable-empty {
  padding: 50px 30px;
  text-align: center;
}
.outingsTable-empty h3 {
  margin: 0;
}

.outingsTable .table {
  max-width: 100%;
  width: 100%;
}
.outingsTable .table thead .btn {
  font-size: 14px;
  padding: 5px;
  font-weight: bold;
  color: #434A54;
}
.outingsTable .table thead .btn .glyphicon {
  font-size: 11px;
  margin-left: 4px;
}
.outingsTable .table > thead > tr {
  background: white;
}
.outingsTable .table > thead > tr > th {
  vertical-align: middle;
  text-align: left;
  white-space: nowrap;
}
.outingsTable .table > thead > tr > th:first-child {
  border-radius: 0 !important;
  text-align: left;
  padding-left: 15px;
}
.outingsTable .table > thead > tr > th.length, .outingsTable .table > thead > tr > th.stewardships, .outingsTable .table > thead > tr > th.visibility {
  min-width: 125px;
  text-align: center;
}
.outingsTable .table > thead > tr > th.length {
  min-width: 125px;
}
.outingsTable .table > thead > tr > th.actions {
  text-align: center;
}
.outingsTable .table > tbody > tr > td {
  text-align: left;
}
.outingsTable .table > tbody > tr > td:first-child {
  padding-left: 15px;
}
.outingsTable .table > tbody > tr > td.length, .outingsTable .table > tbody > tr > td.stewardships, .outingsTable .table > tbody > tr > td.visibility {
  text-align: center;
}
.outingsTable .table > tbody > tr > td.actions {
  text-align: center;
}

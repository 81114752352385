/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.feature-wrap {
  flex: 1 1 100%;
  display: flex;
  flex-flow: column nowrap;
}
.feature-wrap .pageLayout {
  flex: 1 1 100%;
  display: flex;
  flex-flow: column nowrap;
}
.feature-wrap .pageLayout-content {
  flex: 1 1 100%;
  display: flex;
  flex-flow: column nowrap;
}
.feature-wrap .pageLayout-content .posts-wrap .pageLayout-header {
  padding-bottom: 15px;
}
.feature-wrap .pageLayout-content .posts-wrap .pageLayout-content .featuresTable-empty {
  margin: 0;
}
.feature-wrap .pageLayout-content .documentsList-wrap {
  margin: 0;
}

.feature-header__title {
  display: flex;
  flex-flow: row nowrap;
}

.feature-header__titleActions {
  margin-left: auto;
}
.feature-header__titleActions > * {
  margin-left: 8px;
}

.feature-header__breadcrumbs {
  margin: 5px 0 0;
  font-size: 14px;
  color: #6d6e70;
}
.feature-header__breadcrumbs a {
  color: #6d6e70;
  text-decoration: underline;
}
.feature-header__breadcrumbs a:hover {
  color: #606163;
}
.feature-header__breadcrumbs .glyphicon {
  font-size: 12px;
  margin: 0 3px;
}

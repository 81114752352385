/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.outingsActionBar-wrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin-bottom: 10px;
}

.outingsActionBar-actionsRight {
  margin-left: auto;
}
.outingsActionBar-actionsRight > * {
  margin-left: 8px;
}

.outingsActionBar-actions {
  margin-left: auto;
  display: flex;
  flex-flow: row nowrap;
}
.outingsActionBar-actions .btn {
  margin-left: 8px;
}
.outingsActionBar-actions .associationSet {
  width: 220px;
}

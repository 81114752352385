/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.membership-wrap .pageLayout-header {
  background: #656D78;
  padding-top: 10px;
  padding-bottom: 10px;
}
.membership-wrap .panel-body > :last-child {
  margin-bottom: 0;
}

.membership-header__title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.membership-header__title .glyphicon-chevron-left {
  margin-right: 3px;
}

.membership-header__titleText .btn.btn-link {
  color: rgba(255, 255, 255, 0.4);
  margin-left: -15px;
}
.membership-header__titleText h1 {
  color: white;
}

.membership-header__titleActions {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
}
.membership-header__titleActions .btn {
  margin-left: 8px;
}

.membership-infoPanel .control-label {
  display: block;
  margin: 0 0 5px;
}

.membership-form .error-message {
  color: #c55337;
}
.membership-form .form-group.has-error .userSearch > [class$=-container] > [class$=-control],
.membership-form .form-group.has-error .roleSelect__control {
  border-color: #c55337;
}

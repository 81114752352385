/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.app-wrap {
  overflow: hidden;
}

.sidebarLayout {
  display: flex;
  flex-flow: row nowrap;
  height: 100vh;
  max-width: 100%;
  width: 100vw;
}

.sidebarLayout-sidebar {
  flex: 0 0 200px;
  max-width: 200px;
  background: #434A54;
  border-right: 1px solid #383e46;
  color: white;
}

.sidebarLayout-contentWrap {
  flex: 0 1 100%;
  display: flex;
  flex-flow: column nowrap;
  min-width: 0;
}

.sidebarLayout-topbar {
  background: white;
  border-bottom: 1px solid #AAB2BD;
  height: 51px;
  flex: 0 0 auto;
}
.sidebarLayout-topbar .navbar-default {
  border: none;
}

.sidebarLayout-content {
  flex: 1 1 auto;
  background: #F8f4ec;
  display: flex;
  flex-flow: column nowrap;
  overflow-y: auto;
  max-width: 100%;
}

.sidebarLayout-footer {
  flex: 0 0 auto;
  z-index: 0;
}

.posts-wrap {
  display: flex;
  flex: 1 1 100%;
  flex-flow: column nowrap;
  overflow: hidden;
}
.posts-wrap .pageLayout {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 100%;
  overflow: hidden;
}
.posts-wrap .pageLayout .pageLayout-content {
  flex: 1 1 100%;
  display: flex;
  flex-flow: column nowrap;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
}
.posts-wrap .pageLayout .pageLayout-content .featuresTable-empty {
  margin: 15px;
}

.posts-content {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 100%;
}
.posts-content .posts-map {
  flex: 1 1 auto;
  min-width: 0;
  position: relative;
}
.posts-content .posts-map .outerspatialMap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.posts-content .posts-list {
  flex: 0 0 100%;
  padding: 15px;
  overflow-y: auto;
}
.posts-content .posts-loadMore {
  text-align: center;
  margin-top: 15px;
}
.posts-content .postItem + .postItem {
  margin-top: 15px;
}

.events-wrap .pageLayout-content {
  padding: 15px;
}
.events-wrap .pageLayout-header {
  padding-bottom: 15px;
}

.events-header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.events-header .events-actions {
  display: flex;
  margin-left: auto;
}

.events-content .fc-day-grid-event {
  cursor: pointer;
}

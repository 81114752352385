@import './bootflat/global.scss';

/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
$coral-dark: #c55337;
$coral-light: #ed6444;
$moss-dark: #556e32;
$moss-light: #6b8640;
/* Secondary Brand Colors */
$grass-dark: #7da836;
$grass-light: #9aca41;
$meadow-dark: #f3d28e;
$meadow-dark: #c8ae76;
$sky-dark: #44a6cf;
$sky-light: #90cdf0;
$stone-dark: #404041;
$stone-light: #6d6e70;
/* UI Colors */
$background: #F8f4ec; /* Should probably be updated to something that better matches our brand. */

$font-body: 'Karla', sans-serif;
$font-display: 'PT Sans Narrow', sans-serif;

$primary:                     $grass-dark;
$primary-light:               $grass-light;
$secondary:                   $mint-dark;
$bg:                          $background;

$text:                        $darkgray-dark;
$text-medium:                 $stone-dark;
$text-light:                  $stone-light;
$link:                        $sky-dark;
$link-hover:                  $sky-light;

$border-form:                 #aab2bd;
$border-light:                $lightgray-dark;
$border-medium:               $mediumgray-dark;

$color-info:                  $sky-dark;
$color-success:               $grass-dark;
$color-danger:                $coral-dark;
$color-warning:               $sunflower-light;
$color-table-head:            $mediumgray-dark;

$ui-green:                    $grass-light;
$ui-green-text:               darken($ui-green, 8%);
$ui-green-background:         lighten($ui-green, 35%);
$ui-red:                      $grapefruit-light;
$ui-red-text:                 darken($ui-red, 5%);
$ui-red-background:           lighten($ui-red, 30%);
$ui-yellow:                   $sunflower-light;
$ui-yellow-text:              darken($ui-yellow, 10%);
$ui-yellow-background:        lighten($ui-yellow, 18%);
$ui-blue:                     $bluejeans-light;
$ui-blue-text:                darken($ui-blue, 5%);
$ui-blue-background:          lighten($ui-blue, 30%);

$color-sidebar:               $darkgray-dark;
$color-sidebar-border:        darken($color-sidebar, 5%);

$layout-header-height:        50px;

$shadow:                      0 1px 2px rgba(0, 0, 0, 0.2);

/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.mapsTable-empty {
  padding: 50px 30px;
  text-align: center;
}
.mapsTable-empty h3 {
  margin: 0;
}

.mapsTable .table {
  max-width: 100%;
  width: 100%;
}
.mapsTable .table thead .btn {
  font-size: 14px;
  padding: 5px;
  font-weight: bold;
  color: #434A54;
}
.mapsTable .table thead .btn .glyphicon {
  font-size: 11px;
  margin-left: 4px;
}
.mapsTable .table > thead > tr {
  background: white;
}
.mapsTable .table > thead > tr > th {
  vertical-align: middle;
  text-align: center;
}
.mapsTable .table > thead > tr > th.mapsTable--name {
  text-align: left;
}
.mapsTable .table td:first-child {
  padding-left: 15px;
}

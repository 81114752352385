.contentLayout {
  display: flex;
  flex-flow: row nowrap;
}

.contentLayout-sidebar {
  flex: 0 0 auto;
  width: 200px;
}

.contentLayout-content {
  padding-left: 30px;
  flex: 1 1 auto;
}

/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.mediaForm {
  padding: 30px 20px;
}

.mediaForm form {
  display: flex;
  flex-flow: row nowrap;
}

.mediaForm-content {
  padding-right: 30px;
  flex: 1 1 auto;
  max-width: 100%;
}

.mediaForm-preview {
  max-width: 100%;
  margin-bottom: 20px;
  min-height: 400px;
}
.mediaForm-preview img {
  max-width: 100%;
  max-height: 500px;
  border-radius: 2px;
}

.mediaForm-sidebar {
  width: 30%;
  flex: 0 0 auto;
}

.mediaForm-associations h3 {
  font-size: 18px;
  margin: 0 0 15px;
}
.mediaForm-associations .Select-placeholder {
  color: #7da836;
}

/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.mediaView button.btn.btn-default.active,
.mediaView button.btn.btn-default:active {
  background-color: #204d74;
  border-color: #204d74;
  color: white;
}

.mediaModal-dialog .mediaGallery-actionBar,
.mediaView .mediaGallery-actionBar {
  background: #F5F7FA;
  border-bottom: 1px solid #aaa;
  padding: 15px;
}

.mediaGallery-wrap .alert.alert-danger {
  border-radius: 0;
}

.mediaGallery-uploader__wrap {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
}
.mediaGallery-uploader__wrap.mediaGallery-uploader--show {
  max-height: 500px;
}
.mediaGallery-uploader__wrap > :last-child {
  margin-bottom: 0;
}
.mediaGallery-uploader__wrap .uploader-close {
  color: #404041;
}

.mediaGallery-dropzone {
  position: relative;
}

.mediaGallery-content {
  padding: 15px;
}

.mediaGallery-dropzone__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0;
  z-index: 500;
  background: rgba(125, 168, 54, 0.55);
  border: 5px dashed #7da836;
}

.mediaGallery-dropzone__overlay-text {
  padding: 30px 50px;
  background: white;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  border-radius: 5px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.mediaGalleryFilter-wrap {
  position: relative;
}

.mediaGalleryFilter-controlPanel {
  border-radius: 5px;
  padding: 20px;
  position: absolute;
  top: 35px;
  left: 0;
  width: 400px;
  background: white;
  border: 1px solid #aaa;
  z-index: 1000;
}

.mediaGalleryFilter-filter {
  margin-bottom: 10px;
}

.mediaGalleryFilter-filterButton {
  margin-right: 10px;
}

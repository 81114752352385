@import "src/config.scss";

.comment {
  border-top: 1px solid $border-light;
  display: flex;
  flex-flow: row nowrap;
  padding: 8px 16px;
}

.comment-avatar {
  border-radius: 100px;
  display: block;
  height: 30px;
  width: 30px;
  border: 1px solid $border-light;
  overflow: hidden;
  margin-right: 8px;
  background-size: cover;
  background-position: center center;
  flex: 0 0 auto;
}

.comment-content {
  width: 100%;
}

.comment-body {
  > :last-child { margin-bottom: 0; }
}

.comment-title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.comment-attribution {
  h3 {
    font-size: 15px;
    display: inline-block;
    margin: 0;
    font-weight: 600;
  }
  p {
    display: inline-block;
    margin: 0 0 0 10px;
    color: $text-light;
  }
}

.comment-topActions {
  margin-left: auto;
  .btn {
    padding: 0 5px;
  }
  .btn,
  .btn:hover { color: $text-light; }
}
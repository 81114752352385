/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.additionalInfo-section {
  margin: 0 -15px;
  padding: 15px;
  padding-left: 38px;
  background: white;
  position: relative;
}
.additionalInfo-section .additionalInfo-handle {
  height: 27px;
  left: 4px;
  line-height: 27px;
  position: absolute;
  top: 8px;
  width: 27px;
}
.additionalInfo-section .additionalInfo-handle .btn {
  font-size: 16px;
  color: #6d6e70;
  cursor: move;
}
.additionalInfo-section .additionalInfo-handle .btn:hover, .additionalInfo-section .additionalInfo-handle .btn:active, .additionalInfo-section .additionalInfo-handle .btn:focus {
  color: #404041;
}
.additionalInfo-section .additionalInfo-section__title {
  display: flex;
  flex-flow: row nowrap;
}
.additionalInfo-section .additionalInfo-section__title label {
  margin: 0;
}
.additionalInfo-section .additionalInfo-section__actions {
  margin-left: auto;
  transform: translate(0, -5px);
}
.additionalInfo-section .additionalInfo-section__actions .btn {
  color: #434A54;
}
.additionalInfo-section .additionalInfo-section__content > :last-child {
  margin: 0;
}

.additionalInfo-section + .additionalInfo-section {
  border-top: 1px solid #E6E9ED;
}

.additionalInfo-footer {
  border-top: 1px solid #E6E9ED;
  margin: 0 -15px;
  padding: 15px 15px 0;
}
.additionalInfo-footer .btn .glyphicon {
  font-size: 11px;
  margin-right: 5px;
}

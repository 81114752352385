.mediaModal-dialog {
  width: 100%;
  max-width: 1100px;
}
.mediaModal-dialog .modal-body {
  padding: 0;
}

.mediaModal-titleBar__content {
  display: flex;
  flex-flow: row nowrap;
}

.mediaModal-actions {
  margin-left: auto;
}

/* OuterSpatial Colors */
/* Primary Brand Colors (Logo) */
/* Secondary Brand Colors */
/* UI Colors */
/* Should probably be updated to something that better matches our brand. */
.footer {
  background: white;
  border-top: 1px solid #AAB2BD;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.footer .nav {
  display: flex;
  flex-flow: row wrap;
}
.footer .nav > li > a {
  padding: 5px 15px;
  color: #6d6e70;
  font-size: 13px;
}
.footer .nav > li > a:hover {
  background: none;
  text-decoration: underline;
}
.footer .copy {
  margin-left: auto;
  margin: 0 15px 0 auto;
  color: #6d6e70;
  font-size: 11px;
  text-transform: uppercase;
}

.featureInfo--map {
  display: flex;
  flex-flow: column nowrap;
}
.featureInfo--map .panel-body {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
}
.featureInfo--map .featureInfo-map__wrap {
  flex: 1 1 100%;
  display: flex;
  flex-flow: column nowrap;
}

.featureInfo-map__container {
  position: relative;
  flex: 1 1 auto;
}

.featureInfo-map__wrap .mapPreview {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.feature-wrap .mapsList-wrap {
  padding: 0;
}

.featureInfo-mapActions {
  margin-top: 10px;
}
.featureInfo-mapActions > div {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.featureInfo-mapActions p {
  margin: 0;
}

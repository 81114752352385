.image-cropper-modal {
  .container {
    align-items: center;
    display: flex;
    flex-flow: column;
    margin-top: 50px;

    .add-icon {
      height: 50px;
      width: 50px;
    }

    input {
      display: none;
    }

    .img-container {
      margin-top: 30px;
      border-radius: 50%;
      border: 3px solid black;

      img {
        display: block;
        border-radius: 50%;
      }
    }
  }
}

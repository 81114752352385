.contentBundlesTable .table {
  max-width: 100%;
  &> thead > tr > th.visibility,
  &> tbody > tr > td.visibility {
    text-align: center;
  }
}

.contentBundlesTable-empty {
  padding: 50px 30px;
  text-align: center;

  h3 {
    margin: 0;
  }
}

.contentBundlesTable-tableRow > td:last-child,
.contentBundlesTable-tableRow > th:last-child {
  text-align: right;
}
